import useThrowAsyncError from '../../../hooks/asyncErrorHook';
import useAssetManagementFetch from '../../../hooks/fetchHook';

export const formioRequestType = {
    getColumns: "getColumns",
    getTables: "getTables",
    getSelects: "getSelects",
}

export const formioRequestFilter = {
    lookupOnly: "lookupOnly",
    noLookup: "noLookup",
    lookupToFormEntity: "lookupToFormEntity",
    selectOnly: "selectOnly",
    multiSelectOnly: "multiSelectOnly",
}

/**
 * The purpose of this hook is register a Formio Fetch Plugin
 * Documentation : https://github.com/formio/formio.js/wiki/Fetch-Plugin-API
 *
 * This plugin is in charge of intercepting every staticRequest that happen in the FormioBuilder
 * For each request, it will:
 *   - intercept the request with staticRequest
 *   - parse the url to understand what the request is meant to do
 *   - run the relevant query to our api
 *   - process the response data if needed
 *   - fetch the data back to the settingForms of the components
 *
 * For the plugin to work, in the settingForms, the request url should be formated using a custom schema.
 * url = type=<formioRequestType>&filter=<formioRequestFilter>&otherProperty=<someValue>
 *  - 'type' is required
 *  - 'filter' is optional
 *  - other needed properties could be added in the request
 *
 * Example:
 *  - type=getColumns&tableName=buildings (tableName is an extra property needed to run that request)
 *  - type=getColumns&filter=noLookup
 *  - type=getTables
 *
 * Note : the provided url will not get run. It is a fake url. Its purpose is only to trigger the preStatic code with enough information to run the proper request
 */

export default function useFetchPlugin({ tableId }) {
    const { get } = useAssetManagementFetch();
    const throwAsyncError = useThrowAsyncError();

    return {
        name: "fetchPlugin",
        plugin: {
            preRequest: null,
            request: null,
            wrapRequestPromise: null,
            preStaticRequest: null,
            staticRequest: staticRequest,
            wrapStaticRequestPromise: null,
        }
    };

    function staticRequest(arg) {
        if (arg.method !== "GET") return;

        var options = paramsToObject(new URLSearchParams(arg.url));
        if (!options) return;

        switch (options.type) {
            case formioRequestType.getColumns:
                return getColumnsRequest(options);
            case formioRequestType.getTables:
                return getDefaultRequest('form/schema/tables');
            case formioRequestType.getSelects:
                return getDefaultRequest('form/schema/selects');
            default:
                return getDefaultRequest(arg.url);
        }
    }

    function getColumnsRequest(options) {
        let table = options.tableName ?? tableId;
        if (!table) return;

        return get(`form/schema/${table}/columns`)
            .then(d => filterColumns(d, options))
            .catch(error => throwAsyncError(error));
    }

    function getDefaultRequest(url) {
        return get(url)
            .catch(error => throwAsyncError(error));
    }

    function filterColumns(data, options) {
        if (!data || !options.filter) {
            return data;
        }

        switch (options.filter) {
            case formioRequestFilter.noLookup:
                return data.filter(x => x.type !== "Lookup");
            case formioRequestFilter.lookupOnly:
                return data.filter(x => x.type === "Lookup");
            case formioRequestFilter.lookupToFormEntity:
                return data.filter(x => x.type === "Lookup" && x.table === tableId);
            case formioRequestFilter.selectOnly:
                return data.filter(x => x.type === "Select");
            case formioRequestFilter.multiSelectOnly:
                return data.filter(x => x.type === "Multiselect");
            default:
                return data;
        }
    }

    function paramsToObject(entries) {
        const result = {}
        for (const [key, value] of entries) { // each 'entry' is a [key, value] tupple
            result[key] = value;
        }
        return result;
    }
}