import { useState } from "react"

// Hook signature:
// const { searchQuery, searchHandler } = useSearch(nonSearchedData)

export function useSearch() {
    const [searchQuery, setSearchQuery] = useState("");

    const searchHandler = (query: string) => {
        const cleanedQuery = query.toLowerCase().trim()
        setSearchQuery(cleanedQuery);
    }

    return { searchQuery, searchHandler };
}