import AllComponents from 'formiojs/components';

import customButtonCustomComp from "./Button";
import cancelButtonCustomComp from './CancelButton';
import dataErrorMessageCustomComp from './DataErrorMessage';
import formSubmissionCustomComp from "./FormSubmission";
import formSubmissionTemplate from "./FormSubmission/Template";
import kendoGridCustomComp from "./KendoGrid";
import kendoUploadCustomComp from "./KendoUpload";
import lookupCustomComp from "./Lookup";
import { FormComponent } from "@archierose/formbuilder.qrscanner";
import toggleCustomComp from "./Toggle";

const All = {
    Components: {
        ...AllComponents,

        //extention of exisitng components
        button: customButtonCustomComp,

        //new custom components
        kendoGridCustomComp,
        toggleCustomComp,
        kendoUploadCustomComp,
        qrScannerCustomComp: FormComponent,
        lookupCustomComp,
        cancelButtonCustomComp,
        dataErrorMessageCustomComp,
        formSubmissionCustomComp,
    },
    Templates: {
        ...formSubmissionTemplate
    }
}

export default All;