import { UPDATE_ACCOUNT_INFO } from '../ActionTypes'

// Actions
export const updateAccountInfoSuccess = data => ({
    type: UPDATE_ACCOUNT_INFO,
    payload: {
        accountInfo: data || {},
        error: false,
        authenticated: !!data
    }
})

export const updateAccountInfoFailure = error => ({
    type: UPDATE_ACCOUNT_INFO,
    payload: {
        accountInfo: {},
        error: error,
        authenticated: false
    }
})
