import { useMemo, useState } from "react";
import { findIndex, rangeRight } from "lodash";
import Renderers from "../Renderers";
import { InternalProperties } from "../Constants";
import { DataResult } from "@progress/kendo-data-query";
import { DataEditItem } from "./useEditableData";

export interface BulkEditState {
    field: string
    value: any
    startIndex: number
    endIndex: number
}

export interface InlineEditState {
    field: string
    index: number
}

export function useGridEditing(dataResult: DataResult, primaryKey: string, initialColumns: any[], onDataEdit: (items: DataEditItem[]) => void) {

    const [bulkEditState, setBulkEditState] = useState<BulkEditState>();
    const [inlineEditState, setInlineEditState] = useState<InlineEditState>();

    const renderers = new Renderers(enterEdit, exitEdit, InternalProperties.INLINE_EDIT, initialColumns, bulkEditStart, bulkEditHover);

    const modifiedDataResult = useMemo(getModifiedDataResult, [dataResult,  bulkEditState, inlineEditState]);

    function bulkEditStart(dataItem: any, field: string) {
        const index = findIndex(dataResult.data, [primaryKey, dataItem[primaryKey]]);

        const bulkEditState = {
            field: field,
            value: dataItem[field],
            startIndex: index,
            endIndex: index
        }

        setBulkEditState(bulkEditState);
    }

    function bulkEditHover(dataItem: any, field: string) {
        if (!bulkEditState || bulkEditState.field !== field) return;

        const selectedIndex = findIndex(dataResult.data, [primaryKey, dataItem[primaryKey]]);

        //Add items to the selection
        // do this somewhere else by returning an updated dataResult.data value 

        setBulkEditState({ ...bulkEditState, endIndex: selectedIndex });
    }

    function bulkEditFinish() {
        if (!bulkEditState) return;

        const editItems: DataEditItem[] = rangeRight(bulkEditState.endIndex, bulkEditState.startIndex)
            .map((index: number) => {
                const item = dataResult.data[index];
                const id = item[primaryKey];

                return { id, field: bulkEditState.field, value: bulkEditState.value };
            });
        setBulkEditState(undefined);
        onDataEdit(editItems);
    }

    function enterEdit(dataItem: any, field: string) {
        // Needs to be stored in an inline edit state (new) and the currently edited row needs to have
        // a prop "InternalProperties.INLINE_EDIT" set to the field name beinf edited
        const index = findIndex(dataResult.data, [primaryKey, dataItem[primaryKey]]);
        setInlineEditState({
            field: field,
            index: index
        });
    }

    function exitEdit() {
        // reset the inline edit state
        setInlineEditState(undefined);
    }

    // this might need to move out of here
    function onInlineEdit(event: any) {
        const id = event.dataItem[primaryKey];
        onDataEdit([{ id, field: event.field, value: event.value }]);
    }

    function getModifiedDataResult() {
        if (!inlineEditState && !bulkEditState)
            return dataResult;
        const modifiedRange = bulkEditState ? rangeRight(bulkEditState.endIndex, bulkEditState.startIndex) : [];
        const newData = dataResult.data.map((item, index) => {
            if (inlineEditState && index === inlineEditState.index)
                return { ...item, [InternalProperties.INLINE_EDIT]: inlineEditState.field };
            if (bulkEditState && modifiedRange.includes(index))
                return { ...item, [bulkEditState.field]: bulkEditState.value };
            return item;
        });
        return { ...dataResult, data: newData };
    }

    return { onInlineEdit, bulkEditFinish, renderers, hasBulkEditStarted: !!bulkEditState, modifiedDataResult }
}