import { useEffect, useState } from 'react';

import commonConstants from '../util/common';
import { localStorageUtils, getNestedProperty } from '../util/helpers';
import useAssetManagementFetch from './fetchHook';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

// As there is only one sitemap for each app, trying to load the sitemap once
var loadingSitemap;

export default function useBreadCrumbPath() {
    const [tree, setTree] = useState(getTreeFromLocalStorage());

    const { tenant, assetId } = useParams();
    const { get } = useAssetManagementFetch();
    const history = useHistory();
    const tenantPath = `/${tenant}`;

    const submission = useSelector(state => state.form.submission);

    useEffect(() => {
        if (tree || loadingSitemap) return;

        loadingSitemap = true

        // query api for sitemap async call
        get('form/config/sitemap')
            .then(data => {
                localStorageUtils.setWithExpiry(commonConstants.SITEMAP_LOCAL_STORAGE_KEY, data, process.env.REACT_APP_SITEMAP_EXPIRY_MIN);
                setTree(node(data));
            })
            .catch(() => {
                setTree(node({}));
            })
            .finally(() => {
                loadingSitemap = false;
            });
        
    }, [get]);

    var breadcrumbPaths = tree ? buildRecursivePaths([], tree) : [];

    breadcrumbPaths = UpdatePathIds(breadcrumbPaths);

    var breadcrumbProperties = tree && breadcrumbPaths.map(x => x.parentIdKey).filter(x => x && x !== "");

    return { breadcrumbPaths, breadcrumbProperties };

    function getTreeFromLocalStorage() {
        var data = localStorageUtils.getWithExpiry(commonConstants.SITEMAP_LOCAL_STORAGE_KEY)
        if (data) {
            return node(data);
        }
        return null;
    }

    function buildRecursivePaths(breadCrumbPath, treeNode) {

        if (treeNode.path.startsWith(tenantPath) === false) {
            treeNode.path = `${tenantPath}${treeNode.path}`
        }
        var newPath = { tag: treeNode.tag, path: treeNode.path, parentIdKey: treeNode.parentIdKey };
        var updatedPaths = [...breadCrumbPath, newPath];

        if (treeNode.path.replace(':id', assetId) === history.location.pathname) {
            return updatedPaths;
        }
        return treeNode.children.chain(child => buildRecursivePaths(updatedPaths, child));
    }

    /**
     * Replace the :id part of the paths with real Ids
     * Look in child objects to build a relative ParentIdKey to the current entity
     * Example with a sitemap such as 
     *  {
     *      tag: "City",
     *      children: [
     *          {
     *              tag: "Building"
     *              parentIdKey: "City.cityid",
     *              children: [
     *                  {
     *                      tag: "Room",
     *                      parentIdKey: "Building.buildingid
     *                  }
     *              ]
     *          }
     *      ]
     *  }
     *  If the user is on a Building page, no update is needed. The Breadcrumb can directly get the CityId from the Building using the Building.ParentIdKey
     *  But if the user is on a Room page, the Breadcrumb can't directly get the CityId. In that scenario, the Building.ParentIdKey needs to be dynamically updated to 'Building.City.cityid' so the Breadcrumb can get the CityId from a Room entity.
     *  
     *  After the ParentIdKeys are updated, the relevant Ids can be retrieve from the submission
     */
    function UpdatePathIds(paths) {

        var reversePaths = paths.reverse();
        reversePaths.forEach((x, i) => {

            var id = assetId;

            if (i > 0) {
                var parentIdOfChild = reversePaths[i - 1].parentIdKey;
                if (x.parentIdKey
                    && parentIdOfChild
                    && parentIdOfChild.indexOf(".") > 0 // operation is only needed for lookups
                ) {
                    var prefix = parentIdOfChild.split(".").slice(0, -1).join(".");
                    x.parentIdKey = `${prefix}.${x.parentIdKey}`;
                }
                id = getNestedProperty(submission, parentIdOfChild)
            }
            x.path = x.path.replace(':id', id);
        })

        return paths.reverse();
    }
}

function list(elements = []) {
    return {
        value: elements,
        chain: callback => elements.reduce((acc, val) => acc.concat(callback(val)), [])
    }
}

function node({ tag = "", path = "", parentIdKey = "", children = [] }) {
    return {
        tag,
        path,
        parentIdKey,
        children: list(children.map(child => node(child)))
    }
};