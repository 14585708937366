import baseEditForm from "formiojs/components/_classes/component/Component.form";

import { formioRequestFilter, formioRequestType } from "../../Builder/fetchPluginHook";

const settings = (...extend) => {
    return baseEditForm(
        [
            {
                key: "display",
                components: [
                    {
                        key: "placeholder",
                        ignore: true
                    },
                    {
                        type: "checkbox",
                        input: true,
                        defaultValue: true,
                        label: "Use Version 2",
                        key: "isVersion2",
                        tooltip: "Enables using the version 2 grid renderer."
                    },
                ]
            },
            {
                key: "data",
                components: [
                    {
                        type: 'select',
                        input: true,
                        label: "Table",
                        key: "tableSelected",
                        dataSrc: 'url',
                        data: {
                            url: `type=${formioRequestType.getTables}`,
                        },
                        valueProperty: 'value',
                        template: '<span>{{ item.text }}</span>',
                    },
                    {

                        label: "DateTime format",
                        key: "dateTimeFormat",
                        type: "textfield",
                        input: true,
                        tooltip: `The format to apply to dateTimes within the grid. 
                                  Vaild date time formats can be found at https://docs.telerik.com/kendo-ui/globalization/intl/dateformatting. 
                                  An example is 'dd/MM/yy h:mm:ss'.`
                    },
                    {
                        type: 'datagrid',
                        input: true,
                        label: 'Columns',
                        key: 'columns',
                        reorder: true,
                        defaultValue: [{ label: '', value: '' }],
                        conditional: {
                            show: false,
                            when: "tableSelected",
                            eq: "",
                        },
                        components: [
                            {
                                label: "Property",
                                key: "PropertyWell",
                                type: "well",
                                input: false,
                                tableView: false,
                                components: [
                                    {
                                        label: 'Property',
                                        key: 'property',
                                        hideLabel: true,
                                        input: true,
                                        type: 'select',
                                        placeholder: "Column",
                                        dataSrc: 'url',
                                        data: {
                                            //This is a fake url query that is meant to be process by the fetchPluginHook
                                            url: `type=${formioRequestType.getColumns}&tableName={{data.tableSelected.name}}`,
                                        },
                                        valueProperty: '',
                                        template: '<span>{{ item.text }}</span>',
                                    },
                                    {
                                        label: 'Lookup Attribute',
                                        key: 'lookupAttribute',
                                        input: true,
                                        hideLabel: true,
                                        description: 'Lookup Attribute',
                                        placeholder: 'Column',
                                        type: 'select',
                                        dataSrc: 'url',
                                        data: {
                                            //This is a fake url query that is meant to be process by the fetchPluginHook
                                            url: `type=${formioRequestType.getColumns}&tableName={{row.property.table}}`,
                                        },
                                        valueProperty: '',
                                        template: '<span>{{ item.text }}</span>',
                                        weight: -10,
                                        customConditional: "show = row.property.type == 'Lookup'",
                                    },
                                    {
                                        label: 'Select Option Set',
                                        key: 'optionSet',
                                        input: true,
                                        hideLabel: true,
                                        description: 'The options that will populate the select list.',
                                        placeholder: 'Column',
                                        type: 'select',
                                        dataSrc: 'url',
                                        data: {
                                            //This is a fake url query that is meant to be process by the fetchPluginHook
                                            url: `type=${formioRequestType.getSelects}`,
                                        },
                                        valueProperty: '',
                                        template: '<span>{{ item.name }}</span>',
                                        weight: -10,
                                        customConditional: "show = row.property.type === 'Select' || row.property.type === 'Multiselect'",
                                    },
                                    {
                                        label: 'Further Lookup Attribute',
                                        key: 'furtherLookUp',
                                        input: true,
                                        hideLabel: true,
                                        description: 'Second Lookup Display Attribute',
                                        placeholder: 'Column',
                                        type: 'select',
                                        dataSrc: 'url',
                                        data: {
                                            //This is a fake url query that is meant to be process by the fetchPluginHook
                                            url: `type=${formioRequestType.getColumns}&tableName={{row.lookupAttribute.table}}&filter=${formioRequestFilter.noLookup}`,
                                        },
                                        valueProperty: 'value',
                                        template: '<span>{{ item.text }}</span>',
                                        weight: -10,
                                        customConditional: "show = row.lookupAttribute && row.lookupAttribute.type == 'Lookup'",
                                    },
                                ]
                            },
                            {
                                label: 'Display Name',
                                key: 'displayName',
                                input: true,
                                type: 'textfield',
                                allowCalculateOverride: true,
                                logic: [
                                    {
                                        name: "setProperty",
                                        trigger: {
                                            type: "javascript",
                                            simple: {
                                                show: true,
                                                when: "textField"
                                            },
                                            javascript: "result = row.property != {} && row.displayName == ''"
                                        },
                                        actions: [
                                            {
                                                name: "setDisplayName",
                                                type: "value",
                                                value: "value = row.property.text"
                                            }
                                        ]
                                    }
                                ],
                            },
                            {
                                label: "Additional Options",
                                key: "",
                                type: "fieldset",
                                components: [
                                    {
                                        label: 'Enable Header Tooltip',
                                        tooltip: 'If set to true, a tooltip will appear for the header cell of this column',
                                        key: 'enableTooltip',
                                        type: 'checkbox',
                                        defaultValue: false,
                                        dataGridLabel: false,
                                        input: true,
                                        tableView: false
                                    },

                                    {
                                        label: "Header Tooltip Content",
                                        key: "tooltipContent",
                                        type: "textfield",
                                        input: true,
                                        tooltip: "The content of the hover tooltip for the header cell of this column",
                                        customConditional: "show = row.enableTooltip"
                                    },
                                    {
                                        label: 'Show?',
                                        tooltip: 'If set to true, the column will be shown in the grid',
                                        key: 'show',
                                        type: 'checkbox',
                                        defaultValue: true,
                                        dataGridLabel: false,
                                        input: true,
                                        tableView: false
                                    },
                                    {
                                        label: "Options",
                                        key: "options",
                                        type: "container",
                                        customConditional: "show = data.isInlineEditable",
                                        components: [
                                            {
                                                label: 'Is Editable',
                                                key: "isEditable",
                                                type: 'checkbox',
                                                customConditional: "show = data.isInlineEditable",
                                                input: true,
                                            },
                                        ]
                                    },
                                ]
                            },
                        ],
                    },
                    {
                        label: 'Filter On',
                        tooltip: 'A lookup can be selected to filter the grid on the form entity Id. Only work for edit/view forms. Do not support multi-level lookup',
                        key: 'filterProperty',
                        input: true,
                        type: 'select',
                        placeholder: "(Optional) Filter lookup ...",
                        dataSrc: 'url',
                        data: {
                            //This is a fake url query that is meant to be process by the fetchPluginHook
                            url: `type=${formioRequestType.getColumns}&tableName={{data.tableSelected.name}}&filter=${formioRequestFilter.lookupToFormEntity}`,
                        },
                        valueProperty: '',
                        template: '<span>{{ item.text }}</span>',
                    },
                    {
                        label: 'Filter grid data column',
                        tooltip: 'Dataverse column to filter the returned grid data on (Currently doesnt support lookups)',
                        key: 'filterDataColumn',
                        input: true,
                        type: 'textfield',
                        placeholder: "(Optional) Filter grid data column ...",
                    },
                    {
                        type: "columns",
                        key: "filterOnColumns",
                        input: false,
                        columns:
                            [
                                {
                                    components:
                                        [
                                            {
                                                label: 'Filter grid data value',
                                                tooltip: 'The value (or apiKey where the value resides) to use when filtering the above Filter Data Column',
                                                key: 'filterDataValue',
                                                input: true,
                                                type: 'textfield',
                                                placeholder: "(Optional) Filter grid data value ...",
                                            },
                                        ],
                                    width: 6,
                                    offset: 0,
                                    push: 0,
                                    pull: 0,
                                    size: "md"
                                },
                                {
                                    components:
                                        [
                                            {
                                                label: 'Get filter value from api key',
                                                tooltip: 'Should the filter value be from the data at the entered api key',
                                                key: 'filterDataValueFromApiKey',
                                                input: true,
                                                type: 'checkbox',
                                            },
                                        ],
                                    width: 6,
                                    offset: 0,
                                    push: 0,
                                    pull: 0,
                                    size: "md"
                                }
                            ]
                    },
                    {
                        type: "checkbox",
                        input: true,
                        label: "Is Filterable",
                        key: "isFilterable",
                        tooltip: "Enables the filtering of the columns with their field option set."
                    },
                    {
                        type: "checkbox",
                        input: true,
                        label: "Is Groupable",
                        key: "isGroupable",
                        tooltip: "Determines if grouping by dragging and dropping the column headers is allowed."
                    },
                    {
                        type: "checkbox",
                        input: true,
                        label: "Is Resizable",
                        key: "isResizable",
                        tooltip: "If set to true, the user can resize columns by dragging the edges (resize handles) of their header cells."
                    },
                    {
                        type: "checkbox",
                        input: true,
                        label: "Is Reorderable",
                        key: "isReorderable",
                        tooltip: "If set to true, the user can reorder columns by dragging their header cells."
                    },
                    {
                        type: "checkbox",
                        input: true,
                        label: "Is Searchable",
                        key: "isSearchable",
                        tooltip: "If set to true, the user can search for data with a global search bar."
                    },
                    {
                        type: "columns",
                        key: "pageableColumns",
                        input: false,
                        columns:
                            [
                                {
                                    components:
                                        [
                                            {
                                                type: "checkbox",
                                                input: true,
                                                label: "Is Pageable",
                                                key: "isPageable",
                                                tooltip: "Configures the pager of the Grid."
                                            },
                                        ],
                                    width: 6,
                                    offset: 0,
                                    push: 0,
                                    pull: 0,
                                    size: "md"
                                },
                                {
                                    components:
                                        [
                                            {
                                                type: "number",
                                                input: true,
                                                label: "Page Size",
                                                labelPosition: "left-right",
                                                key: "pageSize",
                                                tooltip: "Shows a menu for selecting the page size.",
                                                defaultValue: 20,
                                                conditional: {
                                                    show: true,
                                                    when: "isPageable",
                                                    eq: "true",
                                                },
                                            },
                                        ],
                                    width: 6,
                                    offset: 0,
                                    push: 0,
                                    pull: 0,
                                    size: "md"
                                }
                            ]
                    },
                    {
                        type: "columns",
                        key: "sortByColumns",
                        input: false,
                        columns:
                            [
                                {
                                    components:
                                        [
                                            {
                                                label: 'Sort By',
                                                key: 'sortBy',
                                                input: true,
                                                type: 'select',
                                                placeholder: "(Optional) Sort column ...",
                                                dataSrc: 'url',
                                                data: {
                                                    //This is a fake url query that is meant to be process by the fetchPluginHook
                                                    url: `type=${formioRequestType.getColumns}&tableName={{data.tableSelected.name}}`,
                                                },
                                                defaultValue: null,
                                                valueProperty: '',
                                                template: '<span>{{ item.text }}</span>',
                                            },
                                        ],
                                    width: 6,
                                    offset: 0,
                                    push: 0,
                                    pull: 0,
                                    size: "md"
                                },
                                {
                                    components:
                                        [
                                            {
                                                type: 'select',
                                                input: true,
                                                label: "Direction",
                                                data: {
                                                    values: [
                                                        { value: 'asc', label: 'Ascending' },
                                                        { value: 'desc', label: 'Descending' },
                                                    ]
                                                },
                                                dataSrc: "values",
                                                defaultValue: 'asc',
                                                template: "<span>{{ item.label }}</span>",
                                                key: "sortByDirection",
                                                customConditional: "show = data.sortBy && data.sortBy.value"
                                            },
                                        ],
                                    width: 6,
                                    offset: 0,
                                    push: 0,
                                    pull: 0,
                                    size: "md"
                                }
                            ]
                    },
                    {
                        type: "columns",
                        key: "viewColumns",
                        input: false,
                        columns: [
                            {
                                components: [{
                                    type: "checkbox",
                                    input: true,
                                    label: "Is Viewable",
                                    key: "isViewable"
                                },],
                                width: 6,
                                offset: 0,
                                push: 0,
                                pull: 0,
                                size: "md"
                            },
                            {
                                components:
                                    [
                                        {
                                            label: 'Pass Context to View Form',
                                            tooltip: 'Pass Context to the view form. This applies both filtering of data and sets the submission value for the given context from the current grid.',
                                            key: 'viewContext',
                                            input: true,
                                            type: 'select',
                                            placeholder: "(Optional) Prefill View form ...",
                                            dataSrc: 'url',
                                            data: {
                                                //This is a fake url query that is meant to be process by the fetchPluginHook
                                                url: `type=${formioRequestType.getColumns}&tableName={{data.tableSelected.name}}&filter=${formioRequestFilter.lookupToFormEntity}`,
                                            },
                                            valueProperty: '',
                                            template: '<span>{{ item.text }}</span>',
                                            customConditional: "show = data.isViewable",
                                        },
                                        {
                                            type: "checkbox",
                                            input: true,
                                            label: "Pass Current Context",
                                            key: "passCurrentContextToView",
                                            customConditional: "show = data.isViewable",
                                        },
                                    ],
                                width: 6,
                                offset: 0,
                                push: 0,
                                pull: 0,
                                size: "md"
                            }
                        ]
                    },
                    {
                        type: "checkbox",
                        input: true,
                        label: "Is Inline Editable",
                        tooltip: 'Enables inline editing option and batch submitting. Cannot be used with selectable grids.',
                        key: "isInlineEditable",
                        customConditional: "show = !data.isSelectable && !data.updateFromSubmission || (data.isSelectable && data.isInlineEditable)"
                    },
                    {
                        type: "checkbox",
                        input: true,
                        label: "Is Selectable",
                        key: "isSelectable",
                        tooltip: "Enables the selection of rows in the for the columns with their field option set. Cannot be used with inline editing.",
                        customConditional: "show = !data.isInlineEditable && !data.updateFromSubmission || (data.isSelectable && data.isInlineEditable)"
                    },
                    {
                        type: "checkbox",
                        input: true,
                        label: "Add Grid data to form data object",
                        key: "addDataToForm",
                        tooltip: "Adds the grid data to the form data object so other components can consume the grid data.",
                        customConditional: "show = !data.isInlineEditable && !data.isSelectable"
                    },
                    {
                        type: "checkbox",
                        input: true,
                        label: "Update from Submission Data",
                        key: "updateFromSubmission",
                        tooltip: 'Enable the Grid to update based on form submission data. This is useful to set data in a grid through custom form javascript.',
                        customConditional: "show = !data.isInlineEditable && !data.isSelectable"
                    },
                    {
                        type: "checkbox",
                        input: true,
                        label: "Enable Bulk add",
                        key: "enableBulkAdd",
                        customConditional: "show = data.isSelectable || data.updateFromSubmission"
                    },
                    {
                        label: "Property",
                        key: "BulkAddOptions",
                        type: "well",
                        input: false,
                        tableView: false,
                        customConditional: "show = data.enableBulkAdd",
                        components: [
                            {
                                label: 'Table to add records to',
                                key: 'bulkAddTable',
                                input: true,
                                type: 'select',
                                dataSrc: 'url',
                                data: {
                                    //This is a fake url query that is meant to be process by the fetchPluginHook
                                    url: `type=${formioRequestType.getTables}`,
                                },
                                valueProperty: '',
                                template: '<span>{{ item.text }}</span>',
                                customConditional: "show = data.enableBulkAdd"
                            },
                            {
                                type: "checkbox",
                                input: true,
                                label: "Submit primary key of grid",
                                key: "addPrimaryKey",
                                defaultValue: "true",
                                tooltip: "Do you want to add the primary key values of this grid in the new assets",
                                customConditional: "show = data.enableBulkAdd && data.bulkAddTable.text !== data.tableSelected.name"
                            },

                            {
                                label: 'Primary key column name',
                                tooltip: "Column name of lookup to this grid's table from the submission grid (eg crc5d_MovieId)",
                                key: 'primaryKeyColumn',
                                input: true,
                                type: 'textfield',
                                customConditional: "show = data.addPrimaryKey && data.bulkAddTable.text !== data.tableSelected.name"
                            },
                            {
                                type: "checkbox",
                                input: true,
                                label: "Submit id of current asset",
                                key: "addAssetId",
                                defaultValue: "false",
                                tooltip: "Do you want to add the assetId of the current asset to the submission",
                                customConditional: "show = data.enableBulkAdd"
                            },

                            {
                                label: 'AssetId column name',
                                tooltip: "Column name of lookup to this grid's table from the submission grid (eg crc5d_UniverseId)",
                                key: 'assetIdColumn',
                                input: true,
                                type: 'textfield',
                                customConditional: "show = data.addAssetId"

                            },
                            {
                                type: 'datagrid',
                                input: true,
                                label: 'Extra Fields to prefil when bulk adding',
                                key: 'extraFields',
                                reorder: true,
                                defaultValue: [{ label: '', value: '' }],
                                customConditional: "show = data.bulkAddTable && data.bulkAddTable.text ",
                                components: [
                                    {
                                        label: 'Column',
                                        key: 'submissionColumn',
                                        input: true,
                                        type: 'select',
                                        placeholder: "Column",
                                        dataSrc: 'url',
                                        data: {
                                            //This is a fake url query that is meant to be process by the fetchPluginHook
                                            url: `type=${formioRequestType.getColumns}&tableName={{data.bulkAddTable.text}}`,
                                        },
                                        valueProperty: '',
                                        template: '<span>{{ item.text }}</span>',
                                    },
                                    {
                                        label: 'Api Key on this form',
                                        key: 'apiKey',
                                        input: true,
                                        type: 'textfield',
                                    },
                                ],
                            },
                        ]
                    },
                    {
                        type: "checkbox",
                        input: true,
                        label: "Is Sortable",
                        key: "isSortable",
                        tooltip: "Enables the sorting for the columns with their field option set.",
                    },
                    {
                        type: "checkbox",
                        input: true,
                        label: "Is Downloadable",
                        key: "isDownloadable"
                    },
                    {
                        type: "well",
                        property: "downloadOptions",
                        input: false,
                        customConditional: "show = data.isDownloadable",
                        components: [
                            {
                                label: 'Column to identify entity with',
                                tooltip: 'The column to use to identify the entity with. If this is blank it will default to Id',
                                key: 'downloadEntityIdentifierColumn',
                                input: true,
                                type: 'select',
                                placeholder: "(Optional) Select the identifier column",
                                dataSrc: 'url',
                                data: {
                                    //This is a fake url query that is meant to be process by the fetchPluginHook
                                    url: `type=${formioRequestType.getColumns}&tableName={{data.tableSelected.name}}`,
                                },
                                valueProperty: 'value',
                                template: '<span>{{ item.text }}</span>'
                            },
                            {
                                label: 'Column containing download path',
                                tooltip: '(Required) The column in the table that contains the download path',
                                key: 'downloadPathColumn',
                                input: true,
                                type: 'select',
                                placeholder: "Select the download column",
                                dataSrc: 'url',
                                data: {
                                    //This is a fake url query that is meant to be process by the fetchPluginHook
                                    url: `type=${formioRequestType.getColumns}&tableName={{data.tableSelected.name}}`,
                                },
                                valueProperty: 'value',
                                template: '<span>{{ item.text }}</span>'
                            },
                            {
                                label: 'Column containing name of file',
                                tooltip: 'The column in the table that contains the name of the file',
                                key: 'downloadNameColumn',
                                input: true,
                                type: 'select',
                                placeholder: "(Optional) Select the name column",
                                dataSrc: 'url',
                                data: {
                                    //This is a fake url query that is meant to be process by the fetchPluginHook
                                    url: `type=${formioRequestType.getColumns}&tableName={{data.tableSelected.name}}`,
                                },
                                valueProperty: 'value',
                                template: '<span>{{ item.text }}</span>'
                            }
                        ]
                    },
                    {
                        type: "columns",
                        key: "deleteColumns",
                        input: false,
                        columns: [
                            {
                                components: [
                                    {
                                        type: "checkbox",
                                        input: true,
                                        label: "Is Deleteable",
                                        key: "isDeleteable"
                                    },
                                    {
                                        type: "checkbox",
                                        input: true,
                                        label: "Is Hard Delete",
                                        key: "isHardDelete",
                                        defaultValue: false,
                                        customConditional: 'show = data.isDeleteable'
                                    },
                                ]
                            },
                            {
                                components: [
                                    {
                                        label: 'Deleted Flag column',
                                        tooltip: 'The column containing the deleted flag',
                                        key: 'deletedFlagColumn',
                                        input: true,
                                        type: 'select',
                                        placeholder: "(Required) Select the deleted flag column",
                                        dataSrc: 'url',
                                        data: {
                                            //This is a fake url query that is meant to be process by the fetchPluginHook
                                            url: `type=${formioRequestType.getColumns}&tableName={{data.tableSelected.name}}`,
                                        },
                                        valueProperty: 'value',
                                        template: '<span>{{ item.text }}</span>',
                                        customConditional: 'show = data.isDeleteable'
                                    },
                                    {
                                        type: "textfield",
                                        input: true,
                                        label: "Event To Emit On Delete",
                                        key: "deleteEvent",
                                        tooltip: "the formio event to emit on delete",
                                        defaultValue: "",
                                        customConditional: 'show = data.isDeleteable'
                                    },
                                ]
                            }
                        ]
                    },
                    {
                        type: "columns",
                        key: "editColumns",
                        input: false,
                        columns: [
                            {
                                components: [{
                                    type: "checkbox",
                                    input: true,
                                    label: "Enable Edit Button",
                                    tooltip: 'Enables the edit button to navigate to the edit form',
                                    key: "isEditable",
                                }],
                                width: 6,
                                offset: 0,
                                push: 0,
                                pull: 0,
                                size: "md"
                            },
                            {
                                components:
                                    [
                                        {
                                            label: 'Pass Context to Edit Form',
                                            tooltip: 'Pass Context to the edit form. This applies both filtering of data and sets the submission value for the given context from the current grid.',
                                            key: 'editContext',
                                            input: true,
                                            type: 'select',
                                            placeholder: "(Optional) Prefill Edit form ...",
                                            dataSrc: 'url',
                                            data: {
                                                //This is a fake url query that is meant to be process by the fetchPluginHook
                                                url: `type=${formioRequestType.getColumns}&tableName={{data.tableSelected.name}}&filter=${formioRequestFilter.lookupToFormEntity}`,
                                            },
                                            valueProperty: '',
                                            template: '<span>{{ item.text }}</span>',
                                            customConditional: "show = data.isEditable",
                                        },
                                        {
                                            type: "checkbox",
                                            input: true,
                                            label: "Pass Current Context",
                                            key: "passCurrentContextToEdit",
                                            customConditional: "show = data.isEditable",
                                        },
                                    ],
                                width: 6,
                                offset: 0,
                                push: 0,
                                pull: 0,
                                size: "md"
                            }
                        ]
                    },
                    {
                        type: "checkbox",
                        input: true,
                        label: "Has History",
                        key: "hasHistory"
                    },
                    {
                        type: "checkbox",
                        input: true,
                        label: "Is Exportable",
                        key: "isExportable"
                    },
                    {
                        type: "columns",
                        key: "explicitExportColumns",
                        input: false,
                        columns:
                            [
                                {
                                    components:
                                        [
                                            {
                                                type: "checkbox",
                                                input: true,
                                                label: "Enable Explicit Export",
                                                key: "enableExplicitExport"
                                            },
                                        ],
                                    width: 4,
                                    offset: 0,
                                    push: 0,
                                    pull: 0,
                                    size: "md"
                                },
                                {
                                    components:
                                        [
                                            {
                                                type: "textfield",
                                                input: true,
                                                label: "Button text",
                                                labelPosition: "top",
                                                key: "explicitExportLabel",
                                                defaultValue: "Export",
                                                conditional: {
                                                    show: true,
                                                    when: "enableExplicitExport",
                                                    eq: "true",
                                                },
                                            },
                                        ],
                                    width: 3,
                                    offset: 0,
                                    push: 0,
                                    pull: 0,
                                    size: "md"
                                },
                                {
                                    components:
                                        [
                                            {
                                                type: "textfield",
                                                input: true,
                                                label: "Columns to Export (csv string of column display names)",
                                                labelPosition: "top",
                                                key: "explicitExportColumns",
                                                defaultValue: "",
                                                conditional: {
                                                    show: true,
                                                    when: "enableExplicitExport",
                                                    eq: "true",
                                                },
                                            },
                                        ],
                                    width: 5,
                                    offset: 0,
                                    push: 0,
                                    pull: 0,
                                    size: "md"
                                }
                            ]
                    },
                    {
                        type: "checkbox",
                        input: true,
                        label: "Enable Show/Hide of Columns",
                        key: "enableColumnToggle"
                    },
                    {
                        type: "columns",
                        key: "creationColumns",
                        input: false,
                        columns:
                            [
                                {
                                    components:
                                        [
                                            {
                                                type: "checkbox",
                                                input: true,
                                                label: "Allow Creation",
                                                key: "allowCreation"
                                            },
                                        ],
                                    width: 6,
                                    offset: 0,
                                    push: 0,
                                    pull: 0,
                                    size: "md"
                                },
                                {
                                    components:
                                        [
                                            {
                                                type: "textfield",
                                                input: true,
                                                label: "Creation Label",
                                                labelPosition: "left-right",
                                                key: "creationLabel",
                                                defaultValue: "New",
                                                customConditional: "show = data.allowCreation",
                                            },
                                            {
                                                label: 'Pass Context to Add Form',
                                                tooltip: 'Pass Context to the add form. This applies both filtering of data and sets the submission value for the given context from the current grid.',
                                                key: 'addContext',
                                                input: true,
                                                type: 'select',
                                                placeholder: "(Optional) Prefill Add form ...",
                                                dataSrc: 'url',
                                                data: {
                                                    //This is a fake url query that is meant to be process by the fetchPluginHook
                                                    url: `type=${formioRequestType.getColumns}&tableName={{data.tableSelected.name}}&filter=${formioRequestFilter.lookupToFormEntity}`,
                                                },
                                                valueProperty: '',
                                                template: '<span>{{ item.text }}</span>',
                                                customConditional: "show = data.allowCreation",
                                            },
                                            {
                                                type: "checkbox",
                                                input: true,
                                                label: "Pass Current Context",
                                                key: "passCurrentContextToAdd",
                                                customConditional: "show = data.allowCreation",
                                            },
                                        ],
                                    width: 6,
                                    offset: 0,
                                    push: 0,
                                    pull: 0,
                                    size: "md"
                                }
                            ]
                    },
                    {
                        key: "multiple",
                        ignore: true
                    },
                    {
                        key: "default",
                        ignore: true
                    },
                    {
                        key: "persistent",
                        ignore: true
                    },
                    {
                        key: "dbIndex",
                        ignore: true
                    },
                    {
                        key: "encrypted",
                        ignore: true
                    },
                    {
                        key: "redrawOn",
                        ignore: true
                    },
                    {
                        key: "calculateServer",
                        ignore: true
                    },
                    {
                        key: "allowCalculateOverride",
                        ignore: true
                    },
                    {
                        key: "customDefaultValuePanel",
                        ignore: true
                    },
                    {
                        key: "calculateValuePanel",
                        ignore: true
                    },
                    {
                        type: "datagrid",
                        input: true,
                        label: "Refresh Events",
                        tooltip: "The formio events that will cause the grid to refresh",
                        key: "refreshEvents",
                        reorder: true,
                        defaultValue: [],
                        components: [
                            {
                                type: "textfield",
                                input: true,
                                label: "Event name",
                                key: "eventName",
                                defaultValue: ""
                            }
                        ]
                    },
                ]
            },
            {
                key: "validation",
                components: []
            },
            {
                key: "api",
                components: []
            },
            {
                key: "conditional",
                components: []
            },
            {
                key: "logic",
                components: []
            }
        ],
        ...extend
    );
};

export default settings;