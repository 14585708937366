import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 300,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },

    buttons: {
        '& > *': {
            margin: theme.spacing(1),
        },
    }
}));

export { useStyles }