import { Identity } from ".";

export function useStorageIdentity() {

    const key = "dt-aam-identityToken";

    return {
        getIdentity,
        setIdentity
    };

    function getIdentity() {
        const itemString = sessionStorage.getItem(key);

        if (!itemString)
            return null;

        const item = JSON.parse(itemString);

        if (item?.decoded?.exp * 1000 >= Date.now())
            return item as Identity;

        return null;
    }

    function setIdentity(token: Identity) {
        sessionStorage.setItem(key, JSON.stringify(token));
    }
}
