import React from 'react';
import { useParams } from 'react-router-dom';

import ErrorBoundary from '../components/ErrorBoundary/ErrorBoundary';
import Form from '../components/Form/Form';

// This is a temporary page
export function FormPage() {
    const { tenant, groupName, formId, assetId } = useParams();

    return (
        <ErrorBoundary>
            <Form key={`${tenant}${groupName}${formId}${assetId}`} />
        </ErrorBoundary>
    );
}