import React from 'react';
import { Route, Switch } from 'react-router';

import ErrorMessage from './components/ErrorMessage';
import ConfigurationProvider from './context/configurationContext';
import { FormAdminCreatePage, FormAdminEditorPage, FormAdminIndexPage } from './pages/FormAdminPage';
import { FormPage } from './pages/FormPage';
import HistoryPage from './pages/HistoryPage';
import HomePage from './pages/HomePage';

export default function Routing() {
    return (
        <Switch>
            <Route path="/:tenant/">
                <ConfigurationProvider>
                    <Switch>
                        <Route exact path="/:tenant/">
                            <HomePage />
                        </Route>

                        <Route path="/:tenant/form/:groupName/:formId/:assetId?" component={FormPage} />

                        <Route path="/:tenant/admin/form/edit/:groupName/:formId" component={FormAdminEditorPage} />
                        <Route path="/:tenant/admin/form/create" component={FormAdminCreatePage} />
                        <Route path="/:tenant/admin/form" component={FormAdminIndexPage} />

                        <Route path="/:tenant/history/:tableName/:assetId?" component={HistoryPage} />

                        <Route path="/:tenant">
                            <ErrorMessage message="Path not found" />
                        </Route>
                    </Switch>
                </ConfigurationProvider>
            </Route >
            <Route path="">
                <ErrorMessage message="Tenant is missing" />
            </Route>

        </ Switch >
    );
} 