import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
        maxWidth: "400px"
    },
}));
export const SearchBar = (props) => {
    const [query, setQuery] = useState("")
    const classes = useStyles();

    const handleQueryChange = e => {
        setQuery(e.target.value);
    }

    const handleKeyPress = e => {
        if (e.key === "Enter") {
            props.handleSearch(query);
        }
    }
    return (
        <>
            <InputBase
                label="Search"
                onChange={handleQueryChange}
                value={query}
                onKeyPress={handleKeyPress}
                placeholder="Search"
                className={classes.input}
            />
            <IconButton onClick={() => props.handleSearch(query)}>
                <SearchIcon />
            </IconButton>
        </>
    )
}

SearchBar.propTypes = {
    handleSearch: PropTypes.func,
}