import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import ViewIcon from '@material-ui/icons/Visibility';
import orderBy from 'lodash/orderBy';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import useThrowAsyncError from '../../../hooks/asyncErrorHook';
import useAssetManagementFetch from '../../../hooks/fetchHook';
import Spinner from '../../Spinner';

export default function Index() {
    const [tables, setTables] = useState([]);
    const [loading, setLoading] = useState(false);

    const { get } = useAssetManagementFetch();
    const throwAsyncError = useThrowAsyncError();

    const { tenant } = useParams();

    useEffect(() => {
        setLoading(true);

        get(`admin/form/index`)
            .then((data) => {
                const tables = data.tables.filter(x => x.forms.length);
                const orderedTables = orderBy(tables, ["name"]);
                setTables(orderedTables);
                setLoading(false);
            })
            .catch(error => throwAsyncError(error));
    }, [get, throwAsyncError]);

    return (
        <Container maxWidth="md">
            <Box m={2} display="flex" >
                <Box flexGrow={1}>
                    <Typography variant="h3">Forms</Typography>
                </Box >
                <Box >
                    <Button
                        variant="contained"
                        color="primary"
                        edge="end"
                        href={`/${tenant}/admin/form/create`}
                    >
                        New Form
                    </Button>
                </Box >
            </Box>

            {loading ? <Spinner /> : <FormTable data={tables} tenant={tenant} />}
        </Container>
    );
}

var formTypes = ["Index", "View", "Edit", "Add"];

function FormTable({ data, tenant }) {
    
    return (
        <Table>
            <TableHead>
                <TableRow key={"header"}>
                    <TableCell key={"name"}>Form Name</TableCell>
                    {formTypes.map((form) => (<TableCell key={form}>{form}</TableCell>))}
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((row) => (<FormRow key={row.name} row={row} tenant={tenant} />))}
            </TableBody>
        </Table>
    );
}


function FormRow({ row, tenant }) {
    
    return (
        <TableRow key={row.name}>
            <TableCell>{row.name}</TableCell>
            {formTypes.map((form) => <FormCell key={form} name={row.name} form={form.toLowerCase()} forms={row.forms} tenant={tenant}/>)}
        </TableRow>
    );
}

function FormCell({ name, form, forms, tenant }) {

    if (forms.includes(form) === false) {
        return <TableCell></TableCell>
    }

    return (
        <TableCell>
            <Tooltip title="Edit">
                <IconButton edge="start" aria-label="edit" href={`/${tenant}/admin/form/edit/${name}/${form}`}>
                    <EditIcon />
                </IconButton >
            </Tooltip>
            <Tooltip title="View">
                <IconButton edge="end" aria-label="view" href={`/${tenant}/form/${name}/${form}`}>
                    <ViewIcon />
                </IconButton >
            </Tooltip>
        </TableCell>
    );
}