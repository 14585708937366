import React, { useEffect, useState } from 'react'
import { Box, Dialog, DialogContent, DialogTitle, FormControl, InputLabel, Select, Button, MenuItem, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import useAssetManagementFetch from './../../../hooks/fetchHook';
import useThrowAsyncError from '../../../hooks/asyncErrorHook';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    formItems: {
        marginBottom: theme.spacing(2),
        width: '100%'
    },
    form: {
        display: 'flex',
        flexDirection: "column",
        alignItems: "center"
    },
}));

export default function AddFormGroup(props) {
    const classes = useStyles();

    const [tablesList, setTablesList] = useState([]);
    const [table, setTable] = useState(props.preSelectedTable);
    const [name, setName] = useState('');
    const [nameErrorMessage, setNameErrorMessage] = useState('');
    const { get, post } = useAssetManagementFetch();
    const throwAsyncError = useThrowAsyncError();

    useEffect(() => {
        get("form/schema/tables")
            .then(data => setTablesList(data))
            .catch(error => throwAsyncError(error));
    }, [get, throwAsyncError])

    const form = (
        <form autoComplete="off">
            <Box className={classes.form}>
                <FormControl className={classes.formItems}>
                    <TextField
                        error={nameErrorMessage.length}
                        helperText={nameErrorMessage}
                        id="name"
                        label="Name"
                        onChange={handleNameChange}
                    />
                </FormControl>

                <FormControl variant="outlined" className={classes.formItems}>
                    <InputLabel>Table</InputLabel>
                    <Select
                        disabled={!tablesList}
                        value={table}
                        onChange={handleTableSelectionChange}
                        label="table"
                    >
                        {(tablesList || []).map(table =>
                            <MenuItem key={table.text} value={table.value.name}>{table.text}</MenuItem>
                        )}
                    </Select>
                </FormControl>
            </Box>
        </form>
    )

    return (
        <Dialog open={props.isModalOpen} onClose={props.handleModalClose}>
            <DialogTitle>Create new form group</DialogTitle>
            <DialogContent>
                {form}
            </DialogContent>
            <DialogContent>
                <Box width={1} display="flex" justifyContent="space-between" pb={1}>
                    <Button onClick={props.handleModalClose} variant="contained">Cancel</Button>
                    <Button onClick={handleSubmit} variant="contained" color="primary">Create</Button>
                </Box>
            </DialogContent>
        </Dialog>
    )

    function handleNameChange(event) {
        setName(event.target.value);

        const re = new RegExp('^([a-z]|[0-9]|[_]){1,254}$');

        if (!re.test(event.target.value)) {
            setNameErrorMessage('Invalid group name!');
        } else if (props.currentFormGroups.includes(event.target.value)) {
            setNameErrorMessage('That group name already exists!');
        } else {
            setNameErrorMessage('');
        }
    }

    function handleSubmit() {
        const body = {
            Name: name,
            Table: table
        }

        post(`admin/form/formgroups/${name}`, body)
            .then(() => props.handleModalClose())
            .catch(error => throwAsyncError(error));
    }

    function handleTableSelectionChange(event) {
        setTable(event.target.value);
    }

}

AddFormGroup.propTypes = {
    isModalOpen: PropTypes.bool,
    handleModalClose: PropTypes.func,
    preSelectedTable: PropTypes.string,
    currentFormGroups: PropTypes.arrayOf(PropTypes.string),
}


