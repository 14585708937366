import { Typography } from '@material-ui/core';
import { Upload } from '@progress/kendo-react-upload';
import difference from "lodash/difference"
import isEmpty from "lodash/isEmpty"
import pickBy from "lodash/pickBy"
import React, { useEffect, useState } from 'react';

import useContext from '../../../../hooks/contextHook';
import { kendoUploadMetadataColumnTypes } from "../constants";

export default function Renderer({ component, options }) {
    const [files, setFiles] = useState([]);
    const [error, setError] = useState(null);

    const context = useContext();

    useEffect(() => {
        setError(null);
    }, [files])

    if (!component) return null;

    const saveUrl = `${window.Formio.getApiUrl()}/storage/File/upload`;
    const deleteUrl = `${window.Formio.getApiUrl()}/storage/File/delete`;

    return (
        <React.Fragment>
            <div>
                <Upload
                    autoUpload={component.autoUpload}
                    restrictions={{
                        allowedExtensions: component.allowedExtensions
                    }}
                    batch={component.batch}
                    disabled={component.disabled}
                    files={files}
                    multiple={component.multipleFiles}
                    onAdd={onAdd}
                    onBeforeUpload={onBeforeUpload}
                    onRemove={onRemove}
                    onStatusChange={onStatusChange}
                    saveHeaders={{ Authorization: `Bearer ${options.jwtToken}` }}
                    saveUrl={saveUrl}
                    showActionButtons={component.showActionButtons}
                    removeUrl={deleteUrl}
                    withCredentials={component.withCredentials}
                />
            </div>
            {error && <Typography color="error">{error}</Typography>}
        </React.Fragment>
    );

    function getColValueFromContext(column) {
        const contextProperty = column.property.type === 'Lookup'
            ? `${column.property.navigationProperty}.${column.property.idColumn}`
            : column.property.value;

        return context.set[contextProperty];
    }

    function processMetaDataProperties(metadataProperty) {
        const value = metadataProperty.valueType === kendoUploadMetadataColumnTypes.CONTEXT
            ? getColValueFromContext(metadataProperty)
            : metadataProperty.value;

        const property = metadataProperty.property;

        return property.type === 'Lookup'
            ? {
                name: property.navigationProperty,
                value: {
                    [property.idColumn]: value,
                    table: property.table,
                    id: value
                }
            }
            : { name: property.value, value }
    }

    function onAdd(event) {
        setFiles(event.newState);
    }

    function onBeforeUpload(event) {
        if (isEmpty(component.metadataProperties) || !component.metadataTable) return;

        const pathProperty = (component.metadataProperties).find(col => col.valueType === kendoUploadMetadataColumnTypes.PATH);
        const nameProperty = (component.metadataProperties).find(col => col.valueType === kendoUploadMetadataColumnTypes.NAME);

        const remainingMetaDataColumns = difference(component.metadataProperties, [pathProperty, nameProperty])
            .map(metaDataProperty => processMetaDataProperties(metaDataProperty));

        const additionalData = {
            metadataTable: component.metadataTable.name,
            storageLocation: component.storageLocation,
            metadataProperties: JSON.stringify(remainingMetaDataColumns),
            pathColumn: pathProperty?.property?.value,
            nameColumn: nameProperty?.property?.value
        }

        // remove null or undefined values
        event.additionalData = pickBy(additionalData, (val) => val != null);
    }

    function onRemove(event) {
        setFiles(event.newState);
    }

    function onStatusChange(event) {
        if (!event.response) return;

        if (event.response.response.status === 500) {
            setError("An error occurred on the server while trying to upload the files")
            return;
        }

        const failedFileResponses = event.response.response.filter(result => !result.succeeded);
        const failedFileNames = failedFileResponses.map(result => result.fileName);
        const failedFiles = event.newState
            .filter(file => failedFileNames.includes(file.name))
            .map(file => ({ ...file, status: 0 }));

        // Keep the failed files on the UI with the error message and remove the successful files
        setFiles(failedFiles);

        // trigger update event
        if (component.uploadCompleteEvent) {
            options.events.emit(`formio.${component.uploadCompleteEvent}`)
        }
    }
}