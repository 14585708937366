import { CreateButton } from './CreateButton';
import { Toolbar, ToolbarItem } from "@progress/kendo-react-buttons";
import isEmpty from "lodash/isEmpty";
import { Button, Tooltip } from '@material-ui/core';
import { SearchBar } from '../../SearchBar/SearchBar';
import PropTypes from 'prop-types';


export const GridToolbar = (props) => {
    return (
        <Toolbar className="border-bottom-0">
            {props.enableExplicitExport && props.selectable &&
                <ToolbarItem>
                    {isEmpty(props.selectedIds)
                        ? (
                            <Tooltip title="Please select items to export">
                                <span>
                                    <Button
                                        variant="contained"
                                        disabled
                                    >
                                        {props.explicitExportLabel}
                                    </Button>
                                </span>
                            </Tooltip>
                        )
                        : (
                            <Button
                                variant="contained"
                                onClick={props.handleExplicitExportClick}
                            >
                                {props.explicitExportLabel}
                            </Button>
                        )
                    }
                </ToolbarItem>
            }
            {props.enableExport && props.selectable &&
                <ToolbarItem>
                    {isEmpty(props.selectedIds)
                        ? (
                            <Tooltip title="Please select items to export">
                                <span>
                                    <Button
                                        title="Export Excel"
                                        variant="contained"
                                        disabled
                                    >
                                        Export
                                    </Button>
                                </span>
                            </Tooltip>
                        )
                        : (
                            <Button
                                title="Export Excel"
                                variant="contained"
                                onClick={props.handleExportModalOpen}
                            >
                                Export
                            </Button>
                        )
                    }
                </ToolbarItem>
            }

            {props.enableColumnToggle &&
                <ToolbarItem>
                    <Button
                        title="Show/Hide Columns"
                        variant="contained"
                        onClick={props.handleColumnToggleModalOpen}
                    >
                        Columns
                    </Button>
                </ToolbarItem>
            }

            <CreateButton
                buttonSettings={props.creationButtonSettings}
                tenant={props.tenant}
                addContext={props.addContext}
                tableName={props.tableName}
                onCreateClick={props.onCreateClick}
            />

            {props.enableGlobalSearch &&
                <SearchBar
                    handleSearch={props.handleSearch}
                />
            }

        </Toolbar>
    )
}

GridToolbar.propTypes = {
    enableExplicitExport: PropTypes.bool,
    handleSearch: PropTypes.func,
    enableGlobalSearch: PropTypes.bool,
}
