import { GridCellProps, GridColumn as KendoGridColumn, GridHeaderCellProps } from '@progress/kendo-react-grid';
import { InternalProperties } from '../Constants';
import Actions, { ActionProps } from '../Actions';
import { useState } from 'react';

export interface Column {
    key: string
    attribute: string
    displayName: string
    format: string
    editorType?: 'text' | 'numeric' | 'boolean' | 'date'
    filterType?: 'text' | 'numeric' | 'boolean' | 'date'
    enableTooltip: boolean
    tooltipContent: string
    type: string
    show: boolean
    optionSet: { name: string }
    isLookup?: boolean
    property: any
}

interface ColumnProps {
    accountIdentifier: string
    columns: Column[]
    enableColumnToggle: boolean
    gridId: string
}

export function useColumns(actionProps: ActionProps, columnProps: ColumnProps, isAllSelected: boolean, isSelectable: boolean) {

    const [localStoragePath] = useState(`${columnProps.accountIdentifier}${columnProps.gridId}`);
    const [columns, setColumns] = useState(loadColumns(columnProps.columns, columnProps.enableColumnToggle, localStoragePath));

    function updateColumns(updatedColumns: any[]) {
        const columnsJsonString = JSON.stringify(updatedColumns)
        window.localStorage.setItem(localStoragePath, columnsJsonString);
        setColumns(updatedColumns);
    }

    const columnsToShow = columns.filter((col: Column) => !col.hasOwnProperty("show") || col.show);
    const renderActionColumn = actionProps.editable || actionProps.viewable || actionProps.hasHistory || actionProps.downloadOptions.isDownloadable || actionProps.deleteOptions.isDeleteable;

    const columnElements: JSX.Element[] = columnsToShow.map((col: Column) =>
        <KendoGridColumn
            key={col.key}
            field={col.attribute}
            title={col.displayName}
            format={col.format}
            editor={col.editorType}
            headerCell={customHeaderCell(col)}
            filter={col.filterType}
            filterable={col.type !== "Select"}
        />
    )

    if (isSelectable) {
        columnElements.unshift(
            <KendoGridColumn
                locked={true}
                field={InternalProperties.SELECTED}
                width="70px"
                filterable={false}
                groupable={false}
                resizable={false}
                reorderable={false}
                headerSelectionValue={isAllSelected}
            />
        )
    }

    if (renderActionColumn) {
        columnElements.push(
            <KendoGridColumn
                width="100px"
                filterable={false}
                groupable={false}
                cell={(data: GridCellProps) => <Actions props={actionProps} data={data} />}
                resizable={false}
                reorderable={false}
                locked={true}
            />
        )
    }

    return { columnElements, columns, updateColumns }
}

function customHeaderCell(col: Column) {
    return (props: GridHeaderCellProps) => (
        <div className="k-link" onClick={props.onClick}>
            <span title={col.enableTooltip ? col.tooltipContent : undefined}>{props.title}</span>
        </div>
    );
}

function loadColumns(propColumns: Column[], enableColumnToggle: boolean, localStoragePath: string): Column[] {
    if (!enableColumnToggle)
        return propColumns;

    try {
        const columnsJsonString = window.localStorage.getItem(localStoragePath) || "";
        const columnsJsonObject = JSON.parse(columnsJsonString) as Column[];
        return columnsJsonObject;
    } catch {
        console.warn("Failed to parse column Json");
    }

    return propColumns;
}