import baseEditForm from "formiojs/components/button/Button.form";
import ButtonEditDisplay from 'formiojs/components/button/editForm/Button.edit.display';
import buttonActions from '../../../../enums/ButtonActions';

const settings = (...extend) => {
    const actions = ButtonEditDisplay.find(config => config.key === 'action');
    actions.data.values.push(
        {
            label: "Redirect",
            value: 'redirect'
        },
        {
            label: "API Call",
            value: "api"
        });

    return baseEditForm(
        [
            {
                key: "display",
                components: [
                    {
                        key: "redirectTo",
                        type: "select",
                        label: "Redirect to",
                        input: true,
                        defaultValue: "index",
                        dataSrc: "values",
                        data: {
                            values: [
                                {
                                    label: "Add",
                                    value: "add"
                                },
                                {
                                    label: "Edit",
                                    value: "edit"
                                },
                                {
                                    label: "Index",
                                    value: "index"
                                },
                                {
                                    label: "View",
                                    value: "view"
                                },
                                {
                                    label: "Previous page",
                                    value: "previous"
                                },
                                {
                                    label: "Custom",
                                    value: "custom"
                                }
                            ]
                        },
                        customConditional: "show = data.action === 'submit' || data.action === 'redirect'",
                        weight: 110,
                        template: '<span>{{ item.label }}</span>',
                    },
                    {
                        customConditional: "show = data.action === 'api' || data.action === 'post'",
                        key: 'headers',
                        weight: 100
                    },
                    {
                        type: "well",
                        customConditional: `show = data.action === '${buttonActions.API_CALL}'`,
                        weight: 110,
                        input: false,
                        components: [
                            {
                                label: "Url",
                                key: "apiUrl",
                                type: "textfield",
                                input: true,
                                tooltip: "The url to get data from",
                            },
                            {
                                key: "apiCallMethod",
                                type: "select",
                                label: "Type",
                                input: true,
                                defaultValue: "GET",
                                dataSrc: "values",
                                data: {
                                    values: [
                                        {
                                            label: "Get",
                                            value: "GET"
                                        }
                                    ]
                                },
                                template: '<span>{{ item.label }}</span>',
                            },
                            {
                                label: "Event to emit on loading",
                                key: "loadingEvent",
                                type: "textfield",
                                input: true,
                                defaultValue: "apiCallStarted",
                                tooltip: "The name of the formio event to emit when the api call starts and data is loading"
                            },
                            {
                                label: "Event to emit on success",
                                key: "successEvent",
                                type: "textfield",
                                input: true,
                                defaultValue: "apiCallSuccess",
                                tooltip: "The name of the formio event to emit on successfully completing the api call",
                            },
                            {
                                label: "Event to emit on failure",
                                key: "failEvent",
                                type: "textfield",
                                input: true,
                                defaultValue: "apiCallFail",
                                tooltip: "The name of the formio event to emit on failure of the api call"
                            },
                        ]
                    },
                    {
                        label: "Redirection location",
                        key: "redirectionLocation",
                        type: "textfield",
                        input: true,
                        tooltip: "Location to redirect to after sucsessful scan. Should be the url path containing the form group and form type (excluding leading and trailing /). e.g. vessel/edit",
                        customConditional: "show = data.redirectTo == 'custom'",
                        weight: 110
                    },
                    {
                        label: "Should Redirect with Id",
                        key: "shouldRedirectWithId",
                        type: "checkbox",
                        input: true,
                        tooltip: "Should the redirection contain an Id value.",
                        customConditional: "show = data.redirectTo == 'custom'",
                        weight: 110
                    },
                    {
                        label: "Redirection Id Api Key",
                        key: "redirectionIdApiKey",
                        type: "textfield",
                        input: true,
                        tooltip: "The Api Key containing the Id to be used when redirecting.",
                        customConditional: "show = data.shouldRedirectWithId",
                        weight: 110
                    },
                    {
                        label: "Form values to redirect with",
                        reorder: false,
                        addAnother: "Add Another",
                        addAnotherPosition: "bottom",
                        defaultOpen: false,
                        layoutFixed: false,
                        enableRowGroups: false,
                        initEmpty: false,
                        tableView: true,
                        defaultValue: [
                            {
                                typeId: "",
                                regexValidation: ""
                            }
                        ],
                        key: "formRedirectionFields",
                        customConditional: "show = data.redirectTo == 'custom'",
                        tooltip: "Fields from the current form or form submission response to include in the redirection context",
                        type: "datagrid",
                        weight: 110,
                        input: true,
                        components: [
                            {
                                label: "Redirection context type",
                                tableView: true,
                                key: "redirectionContextType",
                                type: "textfield",
                                input: true,
                                tooltip: "The context type to apply for redirection. Current options include filter/set and context (both filter and set)."
                            },
                            {
                                label: "Field Form ApiKey",
                                tableView: true,
                                key: "redirectionContextData",
                                type: "textfield",
                                input: true,
                                tooltip: "The ApiKey for the data to be added to the redirection context"
                            },
                            {
                                label: "Redirection parameter name",
                                tableView: true,
                                key: "redirectionParameterName",
                                type: "textfield",
                                input: true,
                                tooltip: "The parameter name to use within the context for the redirection"
                            },
                            {
                                label: "Redirection override value",
                                tableView: true,
                                key: "redirectionOverrideValue",
                                type: "textfield",
                                input: true,
                                tooltip: "Override value for the redirection context"
                            },
                            {
                                label: "Redirection requires quotation",
                                tableView: true,
                                key: "redirectionParameterRequiresQuotation",
                                type: "checkbox",
                                input: true,
                                tooltip: "Does the redirection parameter require being surrounded by quotes? Hint most Id fields will require quotation. String fields should not require it."
                            },
                            {
                                label: "Field is primary key",
                                tableView: true,
                                key: "redirectionPrimaryKey",
                                type: "checkbox",
                                input: true,
                                tooltip: "Is this field the primary key for the table of the target form?"
                            },
                        ]
                    },
                    {
                        key: "transferContext",
                        type: "checkbox",
                        input: true,
                        defaultValue: true,
                        label: "Transfer Context",
                        tooltip: "Transfer the context of the current form to the next form. The context can contains filters applied to the form",
                        weight: 110,
                        customConditional: "show = data.action === 'submit' || data.action === 'redirect' ",
                    },
                    {
                        key: "addIfDoesntExist",
                        type: "checkbox",
                        input: true,
                        label: "Add a row of data on submission if no row exists on intial form load",
                        tooltip: "Add a new row to Dataverse if one does not exist upon the forms initial load",
                        weight: 130,
                    }
                ]
            },
        ],
        ...extend
    );
};

export default settings;