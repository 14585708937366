import baseEditForm from "formiojs/components/_classes/component/Component.form";

const settings = (...extend) => {
    return baseEditForm(
        [
            {
                key: "display",
                components: [
                    {
                        label: 'Multiple Data Error Message',
                        key: 'multipleDataErrorMessage',
                        type: 'textfield',
                        input: true,
                        tooltip: 'Error message to display when multiple data items are returned.',
                    },
                    {
                        label: 'No Data Error Message',
                        key: 'noDataErrorMessage',
                        type: 'textfield',
                        input: true,
                        tooltip: 'Error message to display when no data is returned.',
                    },
                    {
                        key: "label",
                        ignore: true
                    },
                    {
                        key: "hideLabel",
                        ignore: true
                    },
                    {
                        key: "labelPosition",
                        ignore: true
                    },
                    {
                        key: "labelWidth",
                        ignore: true
                    },
                    {
                        key: "labelMargin",
                        ignore: true
                    },
                    {
                        key: "placeholder",
                        ignore: true
                    },
                    {
                        key: "tooltip",
                        ignore: true
                    },
                    {
                        key: "errorLabel",
                        ignore: true
                    },
                    {
                        key: "inputMask",
                        ignore: true
                    },
                    {
                        key: "allowMultipleMasks",
                        ignore: true
                    },
                    {
                        key: "prefix",
                        ignore: true
                    },
                    {
                        key: "suffix",
                        ignore: true
                    },
                    {
                        key: "customClass",
                        ignore: true
                    },
                    {
                        key: "tabindex",
                        ignore: true
                    },
                    {
                        key: "description",
                        ignore: true
                    },
                    {
                        key: "autofocus",
                        ignore: true
                    },
                    {
                        key: "hidden",
                        ignore: true
                    },
                    {
                        key: "disabled",
                        ignore: true
                    },
                    {
                        key: "tableView",
                        ignore: true
                    },
                    {
                        key: "modalEdit",
                        ignore: true
                    },
                ]
            },
            {
                key: "data",
                components: [
                    {
                        key: "multiple",
                        ignore: true
                    },
                    {
                        key: "default",
                        ignore: true
                    },
                    {
                        key: "defaultValue",
                        ignore: true
                    },
                    {
                        key: "persistent",
                        ignore: true
                    },
                    {
                        key: "protected",
                        ignore: true
                    },
                    {
                        key: "dbIndex",
                        ignore: true
                    },
                    {
                        key: "encrypted",
                        ignore: true
                    },
                    {
                        key: "redrawOn",
                        ignore: true
                    },
                    {
                        key: "calculateServer",
                        ignore: true
                    },
                    {
                        key: "allowCalculateOverride",
                        ignore: true
                    },
                    {
                        key: "clearOnHide",
                        ignore: true
                    },
                    {
                        key: "customDefaultValuePanel",
                        ignore: true
                    },
                    {
                        key: "calculateValuePanel",
                        ignore: true
                    },
                ]
            },
            {
                key: "validation",
                components: []
            },
            {
                key: "api",
                components: []
            },
            {
                key: "conditional",
                components: []
            },
            {
                key: "logic",
                components: []
            }
        ],
        ...extend
    );
};

export default settings;