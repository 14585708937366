import { Tooltip as KendoTooltip } from "@progress/kendo-react-tooltip";
import { Grid as KendoGrid, GridNoRecords, GridSelectionChangeEvent } from '@progress/kendo-react-grid';
import { useEffect } from 'react';
import { DeleteClickProps, DownloadClickProps, EditClickProps, ViewClickProps } from './Actions';
import { GridToolbar } from './Toolbar';
import useDigitalTwinIntegration, { eventType, notifyDigitalTwin } from '../../hooks/parentWindowIntegrationHook';
import { useSearch } from '../../hooks/useSearchHook';
import * as InternalProperties from './Constants';
import { Column, useColumns } from "./hooks/useColumns"
import { useSelection } from './hooks/useSelection';
import useEditableData from './hooks/useEditableData';
import { useGridState } from './hooks/useGridDataState';
import { useDisplayData } from './hooks/useDisplayData';
import { useGridEditing } from './hooks/useGridEditing';
import { useGridFetch } from './hooks/useGridFetch';
import Spinner from '../Spinner';
import { ApiService, GridComponentFetchData, SortBy, SortByDirection } from "../../types";

interface DynamicDataGridProp {
    apiService: ApiService
    formFetchData: GridComponentFetchData
    tableName: string
    sortable: boolean
    selectable: boolean
    pageable: boolean
    deleteOptions: {
        isDeleteable: boolean
        deletedFlagColumn: string
    },
    downloadOptions: {
        entityIdentifierColumn: string
        isDownloadable: boolean
        pathColumn: string
        nameColumn: string
    },
    editable: boolean
    inlineEditable: boolean
    addContext: string[]
    editContext: string[]
    viewContext: string[]
    filters: any[]
    filterable: boolean
    resizable: boolean
    reorderable: boolean
    itemsPerPage: number
    groupable: boolean
    primaryKey: string
    enableExport: boolean
    enableColumnToggle: boolean
    columns: Column[]
    onChange: (data: any) => void
    creationButton: { label: string } | null
    tenant: string
    enableBulkAdd: boolean
    searchable: boolean
    sortBy: SortBy
    sortByDirection: SortByDirection
    enableExplicitExport: boolean
    explicitExportLabel: string
    onCreateClick: (path: string) => void
    accountIdentifier: string
    gridId: string
    explicitExportColumns: string
    entityGroupName: string
    entityName: string
    hasHistory: boolean
    viewable: boolean
    onDeleteClick: (data: DeleteClickProps) => void,
    onDownloadClick: (data: DownloadClickProps) => void,
    onEditClick: (data: EditClickProps) => void,
    onViewClick: (data: ViewClickProps) => void,
}

export default function DynamicDataGrid(props: DynamicDataGridProp) {
    //error checking
    validateProps(props);

    const { selections: externalSelection, receivedFilter }: any = useDigitalTwinIntegration();

    // filteredIds: an array of all the ids which meet the current filter across all pages

    const { searchQuery, searchHandler } = useSearch();

    const { filterState, pagingState, sortState, onFilterChange, onPageChange, onSortChange } = useGridState(props.itemsPerPage, props.filters, props.sortBy, props.sortByDirection, receivedFilter, props.primaryKey);

    const { gridDataResult, gridDataIds, loading } = useGridFetch(props.apiService, props.formFetchData, pagingState, sortState, filterState, receivedFilter, searchQuery, props.primaryKey, props.columns, props.onChange);

    const { isAllSelected, selectedIds, selectId, toggleSelectAll } = useSelection(externalSelection, gridDataIds);

    const { columnElements, columns, updateColumns } = useColumns(props, props, isAllSelected, props.selectable);

    const { displayDataResult } = useDisplayData(gridDataResult, isAllSelected, props.primaryKey, selectedIds);

    const { onDataAdd, onDataEdit, onSelectionChange } = useEditableData(props.columns, props.enableBulkAdd, props.primaryKey, props.selectable, props.onChange);

    const { onInlineEdit, bulkEditFinish, renderers, hasBulkEditStarted, modifiedDataResult } = useGridEditing(displayDataResult, props.primaryKey, columns, onDataEdit);

    useEffect(() => {
        if (!gridDataIds) return;
        notifyDigitalTwin({ filter: { guids: gridDataIds } }, eventType.SET_FILTER);
    }, [gridDataIds]);

    useEffect(() => {
        const selected = displayDataResult.data.filter((item: any) => item[InternalProperties.SELECTED]);
        onSelectionChange(selected);
    }, [selectedIds]);

    // Changes a single row's selection state
    function onRowSelection({ dataItem }: GridSelectionChangeEvent) {
        const selectedId = dataItem[props.primaryKey];
        const isSelected = dataItem[InternalProperties.SELECTED] !== true;

        selectId(selectedId, isSelected);
    }

    const shouldRenderToolBar = props.enableExport || props.enableColumnToggle || props.creationButton || props.enableExplicitExport;

    return (
        <>
            {shouldRenderToolBar &&
                <GridToolbar
                    enableExplicitExport={props.enableExplicitExport}
                    explicitExportColumns={props.explicitExportColumns}

                    selectable={props.selectable}
                    explicitExportLabel={props.explicitExportLabel}
                    enableColumnToggle={props.enableColumnToggle}
                    enableExport={props.enableExport}
                    exportIds={selectedIds}
                    addContext={props.addContext}
                    creationButtonSettings={props.creationButton}
                    tenant={props.tenant}
                    tableName={props.tableName}
                    onCreateClick={props.onCreateClick}
                    handleSearch={searchHandler}
                    enableGlobalSearch={props.searchable}

                    columns={columns}
                    //tableData={props.tableData}
                    primaryKey={props.primaryKey}

                    updateColumns={updateColumns}
                />
            }
            <KendoTooltip openDelay={100} position="right">
                <span
                    onMouseUp={bulkEditFinish}
                    onMouseLeave={bulkEditFinish} >
                    <KendoGrid
                        {...(props.selectable ? {
                            selectedField: InternalProperties.SELECTED,
                            onRowClick: onRowSelection,
                            onSelectionChange: onRowSelection,
                            onHeaderSelectionChange: toggleSelectAll
                        } : {})}

                        {...(props.inlineEditable ? {
                            cellRender: renderers.cellRender,
                            rowRender: renderers.rowRender,
                            editField: InternalProperties.INLINE_EDIT,
                            onItemChange: onInlineEdit,
                        } : {})}

                        style={{
                            maxHeight: "90vh",
                            ...(hasBulkEditStarted &&
                            {
                                userSelect: "none"
                            })
                        }}

                        scrollable="none"

                        filter={filterState.internal}
                        sort={sortState}
                        {...pagingState}

                        data={loading ? [] : modifiedDataResult}
                        //onDataStateChange: handleGridDataStateChange,
                        onPageChange={onPageChange}
                        onFilterChange={onFilterChange}
                        onSortChange={onSortChange}
                        sortable={props.sortable}
                        pageable={props.pageable}
                        filterable={props.filterable}
                        resizable={props.resizable}
                        reorderable={props.reorderable}
                        groupable={props.groupable}
                        dataItemKey={props.primaryKey}
                    >
                        {columnElements}
                        <GridNoRecords>
                            {loading ? <Spinner mb={5} /> : "There is no data available"}
                        </GridNoRecords>
                    </KendoGrid>
                </span >
            </KendoTooltip>
        </>
    );
}

function validateProps(props: DynamicDataGridProp) {
    if (props.selectable && props.inlineEditable) {
        throw new Error(`Grid cannot be selectable and inline editable.\n
                        Grid configuration must be updated.\n
                        Grid throwing error: ${props.tableName}`)
    }
}