const buttonActions = {
    SUBMIT: 'submit',
    REDIRECT: 'redirect',
    SAVE_STATE: 'saveState',
    EVENT: 'event',
    CUSTOM: 'custom',
    RESET: 'reset',
    OAUTH: 'oauth',
    URL: 'url',
    API_CALL: 'api'
}

export default buttonActions