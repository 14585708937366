import { useMemo } from "react";
import jwt_decode, { JwtPayload } from "jwt-decode";
import { Identity } from ".";
import { getEmbeddedHost } from "../../util/embeddedHost";

export function useApiIdentity() {

    const authDomain = useMemo(getAuthDomain, []);

    return { getIdentity };

    function getIdentity() {

        return new Promise<Identity>((resolve, reject) => {

            const tokenRequestConfig: RequestInit = { method: 'post', mode: 'cors', credentials: 'include' };

            if (!authDomain) {
                reject("No valid auth domain found}");
            }

            fetch(`${authDomain}/identity/token`, tokenRequestConfig)
                .then(response => response.json()
                    .then(token => {

                        if (!token)
                            reject("User authentication failed. No token returned");
                        const jwtString = token.Token ?? token
                        const decoded = jwt_decode<JwtPayload>(jwtString);
                        resolve({ token: jwtString, decoded });
                    }))
                .catch(() => reject("User authentication failed. No token returned"));
        });
    }
}

export function getAuthDomain() {
    return getEmbeddedHost();
}
