import useUriQuery from "./uriQueryHook";

export default function useContext() {
    const uriQueryParams = useUriQuery();
    const context = uriQueryParams.context;

    return {
        filters: { ...context, ...uriQueryParams.filters },
        set: { ...context, ...uriQueryParams.set }
    }
};