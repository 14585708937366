import React from 'react';

import { Authentication } from './components/Authentication/Authentication.jsx'
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import Routing from './Routing';
import { reactPlugin } from './AppInsights'

import './App.css';
import './KendoTheme.scss';
import 'formiojs/dist/formio.full.min.css';
import { QueryClientProvider, QueryClient } from 'react-query';

const client = new QueryClient();
function App() {
    return (
        <AppInsightsContext.Provider value={reactPlugin}>
            <Authentication>
                <QueryClientProvider client={client}>
                    <Routing />
                </QueryClientProvider>
            </Authentication >
        </AppInsightsContext.Provider>
    )
}

export default App;