import { createContext, ReactNode } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import Spinner from "../components/Spinner";

export type ConfigurationContextState = {
    featureFlags: {
        [key: string]: boolean;
    };
};
const defaultContextValue: ConfigurationContextState = {
    featureFlags: {}
};
export const ConfigurationContext = createContext<ConfigurationContextState>(defaultContextValue);

export function ConfigurationProvider({ children }: { children: ReactNode }) {
    const { tenant } = useParams<{ tenant: string }>();
    const token = useSelector((state: any) => state.user.accountInfo)?.jwtIdToken;

    const tenantPath = process.env.NODE_ENV === "development" ? "" : `/${tenant}`;
    const url = `${process.env.REACT_APP_AMM_URL}${tenantPath}/admin/Configuration`;
    const auth = `Bearer ${token}`;

    const { isLoading, error, data } = useQuery("getConfiguration", async () => {
        const res = await fetch(url, { headers: { Authorization: auth } });
        const config = await res.json();
        return config as ConfigurationContextState;
    });

    if (isLoading) return <Spinner />;

    if (error) console.error("Failed to fetch configuration, falling back to default");

    return <ConfigurationContext.Provider value={data ?? defaultContextValue}>{children}</ConfigurationContext.Provider>;
}

export default ConfigurationProvider;
