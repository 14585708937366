import { Breadcrumbs, Link, Typography } from '@material-ui/core';
import { urlBuilder } from '../../util/helpers'
import React from 'react';
import useNavigation from '../../hooks/navigationHook';
import useBreadCrumbPath from '../../hooks/useBreadCrumbPath';


export default function Breadcrumb() {

    const { breadcrumbPaths } = useBreadCrumbPath();

    const currentPage = breadcrumbPaths.pop();

    const { navigationTo } = useNavigation();

    return (
        <Breadcrumbs aria-label="breadcrumb">

            {breadcrumbPaths
                .filter(x => x.tag)
                .map((item, index) => {
                    var navigation = navigationTo({ pathBuilder: urlBuilder(item.path), useContext: true });
                    return (
                        <Link color="inherit" key={index} href={navigation.path} onClick={navigation.go}>{item.tag}</Link>
                    )
                })
            }
            {currentPage ? <Typography color="textPrimary">{currentPage.tag}</Typography> : null}
        </Breadcrumbs>
    )
}