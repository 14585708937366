import React from 'react';
import BackButton from "../BackButton/BackButton";
import ErrorMessage from '../ErrorMessage';

/*
 * Error boundary needs to be a class component because it must implement
 * the lifecyle methods componentDidCatch and getDerivedStateFromError
 * documentation: https://reactjs.org/docs/error-boundaries.html
 */
export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: null, hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true, error };
    };

    onBackClick = () => {
        this.setState({ error: null, hasError: false });
    }

    componentDidCatch(error) {
        // Display fallback UI

        console.error(error);

        this.setState({
            error
        })
    }


    render() {
        if (this.state.hasError) {
            return (
                <ErrorMessage>
                    Something went wrong: {this.state.error.message}
                    <br />
                    <BackButton callback={ this.onBackClick }  style={{ display: "contents" }}/>
                </ErrorMessage>
            )
        }

        return this.props.children;
    }
}