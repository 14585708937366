import { dataTypes } from './CustomComponents/constants'

const defaultAttributeField = x => x.key;

const formComponents = {
    textfield: {
        type: "textfield",
        attributeField: defaultAttributeField,
    },
    textarea: {
        type: "textarea",
        attributeField: defaultAttributeField,
    },
    number: {
        type: "number",
        attributeField: defaultAttributeField,
    },
    checkbox: {
        type: "checkbox",
        attributeField: defaultAttributeField,
    },
    selectboxes: {
        type: "selectboxes",
        attributeField: defaultAttributeField,
    },
    select: {
        type: "select",
        attributeField: defaultAttributeField,
    },
    radio: {
        type: "radio",
        attributeField: defaultAttributeField,
    },
    email: {
        type: "email",
        attributeField: defaultAttributeField,
    },
    url: {
        type: "url",
        attributeField: defaultAttributeField,
    },
    phoneNumber: {
        type: "phoneNumber",
        attributeField: defaultAttributeField,
    },
    address: {
        type: "address",
        attributeField: defaultAttributeField,
    },
    datetime: {
        type: "datetime",
        attributeField: defaultAttributeField,
    },
    day: {
        type: "day",
        attributeField: defaultAttributeField,
    },
    time: {
        type: "time",
        attributeField: defaultAttributeField,
    },
    currency: {
        type: "currency",
        attributeField: defaultAttributeField,
    },
    lookupCustomComp: {
        type: "lookupCustomComp",
        attributeField: x => {
            if (x.dropdownType === dataTypes.multiSelect) {
                return x.multiSelectColumn.value;
            }

            if (x.dropdownType === dataTypes.select) {
                return x.selectColumn.value;
            }

            return x.levels.map(l => l.lookup.navigationProperty).join(".").concat(`.${x.displayAttribute.value}`);
        },
    },
    columns: {
        type: "columns",
    },
    submitButton: {
        type: "button",
        action: "submit"
    },
};

export default formComponents;