import { GridCellProps } from '@progress/kendo-react-grid';
import { MouseEvent } from 'react';

export interface DeleteClickProps {
    tableName: string
    entityId: string
    data: any
}

interface DeleteActionProps {
    data: GridCellProps
    entityGroupName: string
    onClick: (props: DeleteClickProps) => void
    options: DownloadOptions
    primaryKey: string
}

export interface DownloadOptions {
    deletedFlagColumn: string
    isDeleteable: boolean
}

export default function DeleteAction({ data, entityGroupName, onClick, options, primaryKey }: DeleteActionProps) {

    return (
        <a href="#" onClick={onDeleteClick} >
            <span className="k-icon k-i-delete" title="Delete row" />
        </a>
    )

    function onDeleteClick(e: MouseEvent) {
        const updatedData = {
            [options.deletedFlagColumn]: true
        }

        const deleteOptions: DeleteClickProps = {
            tableName: entityGroupName,
            entityId: data.dataItem[primaryKey],
            data: updatedData
        }

        onClick(deleteOptions);
        e.preventDefault();
    }
}