import { GridCellProps } from '@progress/kendo-react-grid';

export interface DownloadClickProps {
    entityIdentifierColumn: string
    entityIdentifierValue: any
    nameColumn: string
    pathColumn: string
    tableName: string
}

interface DownloadActionProps {
    data: GridCellProps
    entityGroupName: string
    onClick: (props: DownloadClickProps) => void
    options: DownloadOptions
    primaryKey: string
}

export interface DownloadOptions {
    entityIdentifierColumn:string
    isDownloadable: boolean
    nameColumn: string
    pathColumn: string
}

export default function DownloadAction({data, entityGroupName, onClick, options, primaryKey}: DownloadActionProps) {
    
    return (
        <a href="#" onClick={() => onDownloadClick(data)}>
            <span className="k-icon k-i-download" title="Download" />
        </a>
    )

    function onDownloadClick(data:GridCellProps) {
        // default the identifier to the primary key
        const entityIdentifierColumn = options.entityIdentifierColumn || primaryKey;

        const downloadOptions: DownloadClickProps = {
            entityIdentifierColumn: entityIdentifierColumn,
            entityIdentifierValue: data.dataItem[entityIdentifierColumn],
            nameColumn: options.nameColumn,
            pathColumn: options.pathColumn,
            tableName: entityGroupName
        };

        onClick(downloadOptions);
    }
    
}