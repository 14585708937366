import { CompositeFilterDescriptor, DataResult, process, SortDescriptor } from "@progress/kendo-data-query";
import { useEffect, useMemo, useState } from "react";
import useThrowAsyncError from "../../../hooks/asyncErrorHook";
import { GridResponce, usefetchGridData } from "../../../hooks/useFetchGridData";
import { ApiService, GridComponentFetchData, CombinedFilterState } from "../../../types";
import { Column } from "./useColumns";

interface GridDatatState {
    loading: boolean
    error?: string
    data: any[]
    count?: number
}

export function useGridFetch(apiService: ApiService, formFetchData: GridComponentFetchData, pagingState: any, sortState: SortDescriptor[], filterState: CombinedFilterState, receivedFilter: any, searchQuery: string, primaryKey: string, columns: Column[], onChange: (data: any) => void) {
    const [gridDataState, setGridDataState] = useState<GridDatatState>({ loading: true, data: [], count: 0 });

    const throwAsyncError = useThrowAsyncError();

    const { fetchTableData } = usefetchGridData(columns, apiService, throwAsyncError, onChange, formFetchData, filterState, sortState, pagingState); // Convert to use Kendo Filters

    useEffect(() => {
        setGridDataState(prev => ({ ...prev, loading: true }));
        fetchTableData().then(onSuccess);
    }, [filterState, sortState, pagingState]);

    const gridDataResult = useMemo<DataResult>(
        () => ({ data: gridDataState.data, total: gridDataState.count ?? 0 }),
        [gridDataState, pagingState]);

    const gridDataIds = useMemo(
        () => gridDataResult.data.map(item => item[primaryKey]),
        [gridDataResult.data, primaryKey]);

    return { loading: gridDataState.loading, gridDataResult, gridDataIds };

    function onSuccess(response: GridResponce | undefined) {
        if (!response) {
            return setGridDataState({ loading: false, data: [], error: "Failed to get data for form" });
        }
        const data = response.data.map((item) => ({ ...item, ...formatValues(item, columns) }));
        setGridDataState({ loading: false, data, count: response.count });
    }

}

function formatValues(item: any, columns: any[]) {
    return columns
        .filter(col => col.filterType === 'date')
        .reduce((transformations, col) => {
            const attributeValue = item[col.attribute]
            if (attributeValue && attributeValue instanceof Date) {
                const dateValue = new Date(attributeValue)
                dateValue.setHours(0, 0, 0, 0)
                transformations[col.attribute] = dateValue
            }
            return transformations
        }, {})
}

function extractDataResult(data: any[], pagingState: any, externalFilter: CompositeFilterDescriptor | undefined, searchQuery: any) {
    const newData = data.filter((item: any) => {
        if (!searchQuery)
            return true;
        return Object.values(item).some((value: any) => value?.toString().toLowerCase().includes(searchQuery));
    });
}