import { Box, Card } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Timeline from '@material-ui/lab/Timeline'
import TimelineItem from '@material-ui/lab/TimelineItem';
import Tooltip from '@material-ui/core/Tooltip';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ChangeHistory from '@material-ui/icons/ChangeHistory';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import History from '@material-ui/icons/History';
import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import useThrowAsyncError from '../../hooks/asyncErrorHook';
import useAssetManagementFetch from '../../hooks/fetchHook';
import Spinner from '../Spinner';
import HistoryDetails from './HistoryDetails';
import useDigitalTwinIntegration, { eventType } from './../../hooks/parentWindowIntegrationHook';

export default function HistoryTimeline({ fullTableName, shouldNotifyDT }) {
    const { tableName, assetId } = useParams();
    const { notifyDigitalTwin } = useDigitalTwinIntegration();

    const { get } = useAssetManagementFetch();
    const throwAsyncError = useThrowAsyncError();

    const [loading, setLoading] = useState(true);
    const [history, setHistory] = useState([]);
    const [columns, setColumns] = useState([]);

    useEffect(() => {
        const fetchData = async () => {

            const requestPromises = [get(`assets/history/${tableName}/${assetId}`), fullTableName ? get(`form/schema/${fullTableName}/columns`) : []];
            try {
                const responses = await Promise.all(requestPromises);
                setHistory(responses[0]);
                setColumns(responses[1])
            } catch (e) {
                throwAsyncError(e)
            }

            setLoading(false);
        }

        fetchData();
    }, [tableName, assetId, get, throwAsyncError, fullTableName]);

    const handleTimeClick = (time) => {
        notifyDigitalTwin({ filter: { timestamp: time } }, eventType.SET_FILTER);
    }

    if (loading) {
        return <Spinner />
    }

    const historyItem = history.map((history, index) => (
        <TimelineItem key={index}>
            <TimelineOppositeContent style={{ flex: 'none' }} />
            <TimelineSeparator>
                <TimelineDot>
                    {history.operation === "Create" ? <ChangeHistory /> : <History />}
                </TimelineDot>
                <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
                <Card >
                    <Box display="flex" flexDirection="row" justifyContent="space-between" width="100%">
                        <Box mx={3} mt={2}>
                            <Typography variant="h4"> {history.operation}</Typography>
                        </Box>
                        <Box mx={3} mt={2}>
                            {shouldNotifyDT
                                ? (
                                    <Tooltip title="Jump to this timestamp" placement="top">
                                        <Button onClick={() => handleTimeClick(history.createdOn)} endIcon={<ArrowForwardIcon />}>
                                            {new Date(history.createdOn).toLocaleString(navigator.language)}
                                        </Button>
                                    </Tooltip>
                                )
                                : new Date(history.createdOn).toLocaleString(navigator.language)
                            }
                        </Box>
                    </Box>
                    <HistoryDetails columns={columns} historyId={history.auditId} />
                </Card>
            </TimelineContent>
        </TimelineItem>
    ));

    return (
        <Timeline align="left">
            {history.length > 0 ? historyItem : <NoDataAlert />}
        </Timeline>
    );
}

function NoDataAlert() {
    return (
        <Box width={1} display="flex" mt={5} justifyContent="center">
            <Alert severity="info">There is no history data for this record</Alert>
        </Box >
    )
}