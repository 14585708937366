import { useCallback, useState } from 'react';

/* Error boundaries dont't catch rejected promises 
   so we can use this trick to throw react errors from rejected promises
   taken from solution provided in this thread https://github.com/facebook/react/issues/14981
*/
const useThrowAsyncError = () => {
    const [, setError] = useState();

    return useCallback(
        e => {
            setError(() => {
                throw e;
            });
        },
        [setError]
    );
};

export default useThrowAsyncError;