import ButtonComponent from 'formiojs/components/button/Button';

import buttonActions from '../../../../enums/ButtonActions';
import { urlBuilder } from '../../../../util/helpers';
import settingsForm from "./Button.settingsForm";

export default class CustomButton extends ButtonComponent {
    static editForm = settingsForm;

    // This method gets attached as a click handler in the parent class
    async onClick(event) {
        if (this.component.action === buttonActions.REDIRECT) {
            this.handleRedirect();
            return;
        }

        if (this.component.action === buttonActions.API_CALL) {
            await this.handleApicall();
            return;
        }

        super.onClick(event);
    }

    async handleApicall() {
        const fetchOptions = {
            method: this.component.apiCallMethod,
            headers: this.component.headers
                .filter(headerRow => headerRow.header)
                .reduce((acc, headerRow) => ({
                    ...acc,
                    [headerRow.header]: headerRow.value
                }), {})
        }

        try {
            this.options.events.emit(`formio.${this.component.loadingEvent}`)

            const response = await fetch(this.component.apiUrl, fetchOptions);

            if (!response.ok) throw new Error(response.message)

            // only handling get requests for now
            if (this.component.apiCallMethod === 'GET') {
                const data = await response.json();
                this.options.events.emit(`formio.${this.component.successEvent}`, data);
            }
        } catch (error) {
            this.options.events.emit(`formio.${this.component.failEvent}`, error.message);
        }
    }

    handleRedirect() {
        const parentForm = this.parent;
        const options = this.options;
        const component = this.component;

        options.navigation.redirect({
            locationToRedirect: this.component.redirectTo,
            transferContext: this.component.transferContext,
            customRedirectCallback: customRedirectCallback
        });

        function customRedirectCallback() {
            let baseRedirectionUrl = `/${options.tenant}/form/${component.redirectionLocation}`;
            
            if (component.redirectionIdApiKey) baseRedirectionUrl += `/${parentForm.data[component.redirectionIdApiKey]}`
            const pathBuilder = urlBuilder(baseRedirectionUrl);

            const redirectionFields = component.formRedirectionFields
                .filter(field => field.redirectionOverrideValue || parentForm.data[field.redirectionContextData]);

            for (const field of redirectionFields) {
                const redirectionValue = field.redirectionOverrideValue || parentForm.data[field.redirectionContextData];
                const sanitisedRedirectionValue = field.redirectionParameterRequiresQuotation ? `'${redirectionValue}'` : redirectionValue;

                pathBuilder.addQuery(`${field.redirectionContextType}[${field.redirectionParameterName}]`, `${sanitisedRedirectionValue}`);
            }

            options.navigation.navigationTo({ pathBuilder }).go();
        }
    }

    // This gets called by the formio Component.js file
    attach(element) {
        const superAttach = super.attach(element);

        this.on('change', () => {
            // Enable redirect buttons even on view only pages
            if (this.component.action === buttonActions.REDIRECT) {
                this.setDisabled(this.refs.button, false);
            }
        });

        return superAttach;
    }
}