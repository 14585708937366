import baseEditForm from "formiojs/components/_classes/component/Component.form";

const settings = (...extend) => {
    return baseEditForm(
        [
            {
                key: "display",
                components: [
                    {
                        label: "Submission Table",
                        key: "submissionTable",
                        type: "textfield",
                        input: true,
                        tooltip: "Table to submit the data within this form submission to",
                        required: true,
                        weight: 0
                    },
                    {
                        label: "Asset Id Field",
                        key: "assetIdField",
                        type: "textfield",
                        input: true,
                        tooltip: "The field in the form containing the asset id for the form submission",
                        weight: 1
                    },
                    {
                        label: "Subcomponent prefix",
                        key: "subPrefix",
                        type: "textfield",
                        input: true,
                        tooltip: "Text entered here will be removed from the beginning of any subcomponents' api keys. Only lowercase letters are allowed. (optional)",
                        required: false,
                        weight: 2,
                        customValidation: "valid = /^[a-z]+$/.test(input);"
                    },
                    {
                        key: "placeholder",
                        ignore: true
                    },
                    {
                        key: "tooltip",
                        ignore: true
                    },
                    {
                        key: "errorLabel",
                        ignore: true
                    },
                    {
                        key: "inputMask",
                        ignore: true
                    },
                    {
                        key: "allowMultipleMasks",
                        ignore: true
                    },
                    {
                        key: "prefix",
                        ignore: true
                    },
                    {
                        key: "suffix",
                        ignore: true
                    },
                    {
                        key: "tabindex",
                        ignore: true
                    },
                    {
                        key: "description",
                        ignore: true
                    },
                    {
                        key: "autofocus",
                        ignore: true
                    },
                    {
                        key: "modalEdit",
                        ignore: true
                    },
                ]
            },
            {
                key: "data",
                components: [
                    {
                        key: "multiple",
                        ignore: true
                    },
                    {
                        key: "default",
                        ignore: true
                    },
                    {
                        key: "defaultValue",
                        ignore: true
                    },
                    {
                        key: "persistent",
                        ignore: true
                    },
                    {
                        key: "protected",
                        ignore: false
                    },
                    {
                        key: "dbIndex",
                        ignore: true
                    },
                    {
                        key: "encrypted",
                        ignore: true
                    },
                    {
                        key: "redrawOn",
                        ignore: true
                    },
                    {
                        key: "calculateServer",
                        ignore: true
                    },
                    {
                        key: "allowCalculateOverride",
                        ignore: true
                    },
                    {
                        key: "clearOnHide",
                        ignore: true
                    },
                    {
                        key: "customDefaultValuePanel",
                        ignore: true
                    },
                    {
                        key: "calculateValuePanel",
                        ignore: false
                    },
                ]
            },
            {
                key: "validation",
                ignore: true
            }
        ],
        ...extend
    );
};

export default settings;