import { GridCellProps } from '@progress/kendo-react-grid';
import DeleteAction, { DeleteClickProps } from './DeleteAction';
import DownloadAction, { DownloadClickProps } from './DownloadAction';
import EditAction, { EditClickProps } from './EditAction';
import HistoryAction from './HistoryAction';
import ViewAction, { ViewClickProps } from './ViewAction';

export type { DeleteClickProps } from './DeleteAction';
export type { DownloadClickProps } from './DownloadAction';
export type { EditClickProps } from './EditAction';
export type { ViewClickProps } from './ViewAction';

interface ColumnsProps {
    props:ActionProps, 
    data:GridCellProps
}

export interface ActionProps {
    deleteOptions: { 
        deletedFlagColumn: string,
        isDeleteable: boolean,  
    }
    downloadOptions: { 
        entityIdentifierColumn:string,
        isDownloadable: boolean,
        nameColumn: string,
        pathColumn: string,
    }
    editable: boolean,
    editContext: any,
    entityGroupName: string,
    entityName: string,
    hasHistory: boolean,
    primaryKey: string,
    onDeleteClick: (data:DeleteClickProps) => void,
    onDownloadClick: (data:DownloadClickProps) => void,
    onEditClick: (data:EditClickProps) => void,
    onViewClick: (data:ViewClickProps) => void,
    tableName: string,
    tenant: string,
    viewable: boolean,
    viewContext:any,
}

export default function Actions({props, data}: ColumnsProps) {

    const renderActionColumn = props.editable || props.viewable || props.hasHistory || props.downloadOptions.isDownloadable || props.deleteOptions.isDeleteable;
    
    if (!renderActionColumn) {
        console.warn("Action column not rendered because the configuration is missing for all options")
        return null;
    }
        
    return (
        <td className={data.className} style={data.style}>
            <div style={{display:"flex", justifyContent:"space-evenly"}}>
                {props.editable && 
                    <EditAction apiInfo={props} context={props.editContext} data={data} onClick={props.onEditClick} />}
                {props.viewable &&
                    <ViewAction apiInfo={props} context={props.viewContext} data={data} onClick={props.onViewClick} />}
                {props.hasHistory &&
                    <HistoryAction apiInfo={props} data={data} entityName={props.entityName} />}
                {props.downloadOptions.isDownloadable &&
                    <DownloadAction data={data} entityGroupName={props.entityGroupName} onClick={props.onDownloadClick} options={props.downloadOptions} primaryKey={props.primaryKey} />}
                {props.deleteOptions.isDeleteable && 
                    <DeleteAction data={data} entityGroupName={props.entityGroupName} onClick={props.onDeleteClick} options={props.deleteOptions} primaryKey={props.primaryKey} />}
            </div>
        </td>
    )

}