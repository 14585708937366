import { useEffect, useState } from "react";
import { dataTypes } from "../../Form/CustomComponents/constants";
import { InternalProperties } from '../Constants';

interface EditedState {
    [id: string]: EditedStateItem
}

interface EditedStateItem {
    id: string
    body: any
}

export interface DataEditItem {
    id: string,
    field: string,
    value: any
}

export default function useEditabeData(columns: any[], enableBulkAdd:boolean, primaryKey:string, selectable: boolean, onChange: (data: any) => void) {

    const [selectedState, setSelectedState] = useState<any[]>([]);
    const [addState, setAddState] = useState<any[]>([]);
    const [editState, setEditState] = useState<EditedState>({});

    // Inform formio of data loaded so other form components of what has been selected
    useEffect(() => {
        if (!onChange || !selectable || enableBulkAdd) return;

        const selectedData = selectedState
            .map(item => ({ ...item, [InternalProperties.SELECTED]: true }));

        onChange(selectedData);

    }, [selectedState]);

    // Inform the submission of new items to bulk add
    useEffect(() => {
        if (!onChange || !selectable || !enableBulkAdd) return;

        onChange({ data: addState, primaryKey: primaryKey });
    }, [addState]);

    // Inform the submission of edited items
    useEffect(() => {
        if (!onChange) return;

        onChange({ Data: Object.values(editState) });
    }, [editState]);

    function onSelectionChange(selectedItems:any[]) {
        setSelectedState(selectedItems);
    }

    function onDataAdd(newItems:any[]) {
        setAddState(newItems);
    }

    function onDataEdit(items:DataEditItem[]) {

        items.forEach(item => {
            const columnType = columns.find(col => col.key === item.field).property.type;
            item.value = columnType === dataTypes.integer 
                    ? Math.round(item.value) 
                    : item.value;
        });

        setEditState(prev => items.reduce(editedDataState, prev));
    }

    return { onDataAdd, onDataEdit, onSelectionChange }
}

function editedDataState(state: EditedState, editValue: DataEditItem): EditedState {
    const editItem = state[editValue.id] || { id: editValue.id, body: {} };
    editItem.body[editValue.field] = editValue.value;

    return { ...state, [editValue.id]: editItem };
}