function getEmbeddedHost(ancestorOrigins = []) {
    try {
        const origin = ancestorOrigins[0];
        const allowedOrigins = process.env.REACT_APP_ALLOWED_HOST_DOMAINS.split(";").map(x => new RegExp(`(^|\\.)${x}$`, 'i'));  
        const isAllowed = allowedOrigins.find(o => o.test(origin));

        if (isAllowed) {
            return origin;
        }
    } catch (ex) {
        console.warn("could not access document.location.ancestorOrigins");
    }

    return process.env.REACT_APP_DEFAULT_HOST_DOMAIN;
}

const embeddedHost = getEmbeddedHost(document.location.ancestorOrigins);
const tokenProviderEndpoint = `${embeddedHost}/api/v1/identity/token`;

export {
    embeddedHost,
    tokenProviderEndpoint,
    getEmbeddedHost
}