import { GridCellProps } from '@progress/kendo-react-grid';
import { ApiInfo} from '../hooks/getActionPath';

interface HistoryActionProps {
    apiInfo: ApiInfo
    data: GridCellProps
    entityName: string
}

export default function HistoryAction({apiInfo, data, entityName}: HistoryActionProps) {
    const id = data.dataItem[apiInfo.primaryKey];
    const path =  `/${apiInfo.tenant}/history/${entityName}/${id}?table=${apiInfo.tableName}`;
    return (
        <a href={path}>
            <span className="k-icon k-i-clock" title="History" />
        </a>
    )
}