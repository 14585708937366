import { useMemo } from "react";

interface TableSelected {
    name: string
}

export function useRedirectFormGroup(tableSelected: TableSelected, tableId: string, groupName: string) {
    const redirectFormGroup = useMemo(() => 
        getRedirectFormGroup(tableSelected, tableId, groupName), 
        [tableSelected, tableId, groupName]);

    return { redirectFormGroup };
}

function getRedirectFormGroup(tableSelected: TableSelected, tableId: string, groupName: string): string {
    if (!tableSelected) return '';

    if (tableSelected.name === tableId) {
        return groupName;
    }
    return tableSelected.name;
}