import React from 'react';
import { useSelector } from 'react-redux';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';

export default function HomePage({ provider, logout }) {
    const accountInfo = useSelector(state => state.user.accountInfo);

    return (
        <div style={{ margin: '40px 0' }}>
            <Breadcrumb />
            {accountInfo && (
                <div style={{ wordWrap: 'break-word' }}>
                    <p>
                        <span style={{ fontWeight: 'bold' }}>Username:</span> {accountInfo.account.userName}
                    </p>
                    <p>
                        <span style={{ fontWeight: 'bold' }}>Name:</span> {accountInfo.account.name}
                    </p>
                </div>
            )}
        </div>
    );
}