import { GridCellProps } from '@progress/kendo-react-grid';
import getActionPath, { ApiInfo, ContextParams} from '../hooks/getActionPath';

export interface EditClickProps {
    id: string
    action: any
}

interface EditActionProps {
    apiInfo: ApiInfo
    context: ContextParams
    data: GridCellProps
    onClick: (props: EditClickProps) => void
}

export default function EditAction({apiInfo, context, data, onClick}: EditActionProps) {
    const path = getActionPath(data, "edit", context, apiInfo)
    return (
        <a href={path} onClick={() => onEditClick(data)}>
            <span className="k-icon k-i-edit" title="Edit row" />
        </a>
    )

    function onEditClick(data:GridCellProps) {
        if (!onClick) {
            console.warn(`onEditClick triggered for table ${apiInfo.tableName} without an onEditClick action defined`);
            return;
        }
        
        const id = data.dataItem[apiInfo.primaryKey];
        const action = `grid/${apiInfo.tableName}/edit`
        onClick({ id, action });
    }
}