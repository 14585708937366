import { useMemo, useState } from 'react';

import useContext from '../../../../hooks/contextHook';
import DynamicDataGrid from '../../../DynamicDataGridV2';

import { getLastUrlParam } from '../../../../hooks/useFetchGridData';
import { GridComponentData, FormContext, OptionsData, FilterData, GridDownloadOptions } from '../../../../types';
import { useFilters } from '../../../../hooks/useFilters';
import { useGridColumns } from '../../../../hooks/useGridColumns';
import { useRedirectFormGroup } from '../../../../hooks/useRedirectFormGroup';
import { useGridActions } from './hooks/useGridActions';
import DeleteModal from '../../../DynamicDataGridV2/Modals/DeleteModal';

const pageTypes = new Set(["edit", "view", "add", "index"]);

interface RenderProps {
    component: GridComponentData
    options: OptionsData
    onChange: (data: any) => void
}

type DeleteOptions = {
    isDeleteable: boolean
    isHardDelete: boolean
    deletedFlagColumn: string
}

type DeleteModalState = {
    tableName: string
    entityId: string
    data: any
};

export default function Renderer({ component, options, onChange }: RenderProps) {

    const filterData = useMemo<FilterData>(() => ({
        valueFromApiKey: component.filterDataValueFromApiKey,
        value: component.filterDataValue,
        column: component.filterDataColumn
    }), [component.filterDataValueFromApiKey, component.filterDataValue, component.filterDataColumn]);

    const downloadOptions = useMemo<GridDownloadOptions>(() => ({
        isDownloadable: component.isDownloadable,
        pathColumn: component.downloadPathColumn,
        nameColumn: component.downloadNameColumn,
        entityIdentifierColumn: component.downloadEntityIdentifierColumn
    }), []);

    const deleteOptions = useMemo<DeleteOptions>(() => ({
        isDeleteable: component.isDeleteable,
        isHardDelete: component.isHardDelete,
        deletedFlagColumn: component.deletedFlagColumn
    }), []);

    const { gridColumns } = useGridColumns(component.columns, component.dateTimeFormat);
    const { redirectFormGroup } = useRedirectFormGroup(component.tableSelected, options.root.form.tableId, options.groupName);
    const { filters } = useFilters(filterData, options, gridColumns);
    const gridActions = useGridActions(options.fetch, { ...deleteOptions });

    //const [data, setData] = useState(staticData as any[]);
    const context = useContext();
    const [deleteModalData, setDeleteModalData] = useState<DeleteModalState | null>(null);

    /*
    useEffect(() => {
        options.events.addListener("formio.change", updateDataEventListener);
        return () => {
            options.events.removeListener("formio.change", updateDataEventListener);
        };
    }, []);
    
    function updateDataEventListener(e: any) {
        if (!component.updateFromSubmission || options.attachMode === "builder") return;
        setData(flattenObjInArray(e.data[component.key]?.data) || []);
    }
    
    useEffect(() => {
        if (isEmpty(component.refreshEvents)) return;

        for (const event of component.refreshEvents.filter(event => !isEmpty(event.eventName))) {
            options.events.addListener(`formio.${event.eventName}`, () => {
                const shouldFetchData = data && options.fetch && component.tableSelected && !component.updateFromSubmission;
                if (shouldFetchData)
                    fetchTableData().then((data) => setData(data || []));
            });
        }

        return () => {
            for (const event of component.refreshEvents) {
                options.events.removeListener(`formio.${event.eventName}`, () => {
                    const shouldFetchData = data && options.fetch && component.tableSelected && !component.updateFromSubmission;
                    if (shouldFetchData)
                        fetchTableData().then((data) => setData(data || []));
                });
            }
        };
    }, [component.refreshEvents, options.events])
    */

    const downloadConfigurationIsInvalid = component.isDownloadable && !component.downloadPathColumn;
    const deleteConfigurationIsInvalid = component.isDownloadable && !component.deletedFlagColumn;
    const configurationIsInvalid = !component || !component.tableSelected || downloadConfigurationIsInvalid || deleteConfigurationIsInvalid;
    if (configurationIsInvalid) {
        return <div>Table configuration invalid</div>;
    }

    /*
    const isDataFetchRequired = !(options.preview || options.attachMode === "builder" || data);
    if (isDataFetchRequired) {
        //Wait for the form submission data to be ready, so the grid understand which context it renders against
        options.root.submissionReady.then(() => {
            const shouldFetchData = data && options.fetch && component.tableSelected && !component.updateFromSubmission;
            if (shouldFetchData)
                fetchTableData().then((data) => setData(data || []));
        });
    }
    */

    return <>
        <DynamicDataGrid
            apiService={options.fetch}
            accountIdentifier={options.accountIdentifier}
            formFetchData={component}
            selectable={component.isSelectable}
            sortable={component.isSortable}
            sortBy={component.sortBy}
            sortByDirection={component.sortByDirection}
            pageable={component.isPageable}
            filters={filters}
            filterable={component.isFilterable}
            resizable={component.isResizable}
            reorderable={component.isReorderable}
            groupable={component.isGroupable}
            itemsPerPage={component.pageSize}
            columns={gridColumns}
            deleteOptions={deleteOptions}
            downloadOptions={downloadOptions}
            tableName={redirectFormGroup}
            entityName={component.tableSelected.logicalName}
            entityGroupName={component.tableSelected.name}
            editable={component.isEditable}
            inlineEditable={component.isInlineEditable}
            hasHistory={component.hasHistory}
            creationButton={component.allowCreation ? { label: component.creationLabel } : null}
            primaryKey={component.tableSelected.idColumn}
            viewable={component.isViewable}
            onCreateClick={gridActions?.onCreate}
            onDeleteClick={(data) => setDeleteModalData(data)}
            onDownloadClick={gridActions?.onDownload}
            onViewClick={(d) => gridActions?.onView(d.id, d.action)}
            onEditClick={(d) => gridActions?.onEdit(d.id, d.action)}
            enableExport={component.isExportable}
            enableExplicitExport={component.enableExplicitExport}
            explicitExportLabel={component.explicitExportLabel}
            explicitExportColumns={component.explicitExportColumns}
            enableColumnToggle={component.enableColumnToggle}
            onChange={onChange}
            addContext={getContextQuery(component.addContext, component.passCurrentContextToAdd)}
            editContext={getContextQuery(component.editContext, component.passCurrentContextToEdit)}
            viewContext={getContextQuery(component.viewContext, component.passCurrentContextToView)}
            gridId={component.id}
            tenant={options.tenant}
            enableBulkAdd={component.enableBulkAdd}
            searchable={component.isSearchable}
        />
        <DeleteModal open={!!deleteModalData} onClose={() => setDeleteModalData(null)} onSubmit={() => onDelete()} />
    </>

    // This is to handle which form to go to when buttons are clicked on the grid. 
    // If the grid is for a different table to the table for the current form, then the buttons will go
    // to forms in the formgroup with the same name as the table of the grid.
    // If the grid is for the same table as the form, then the buttons will go to the forms for the 
    // formgroup of the form. 

    function getContextQuery(formContext: FormContext, passOriginalContext: FormContext) {
        const originalContext = window.location.search.substring(1);
        const contextToPass = passOriginalContext ? [originalContext] : [];

        if (!formContext || !Object.keys(formContext).length) return contextToPass;

        const contextKey = `context[${formContext.navigationProperty}.${formContext.idColumn}]`;
        const filterValue = getLastUrlParam(window.location.pathname);
        const filterValueIsAssetId = !pageTypes.has(filterValue);

        if (filterValueIsAssetId) {
            contextToPass.push(`${contextKey}=${filterValue}`);
            return contextToPass;
        }

        const contextContainsFilter = context.filters && context.filters[formContext.value];
        if (contextContainsFilter) {
            contextToPass.push(`${contextKey}=${context.filters[formContext.value]}`);
            return contextToPass;
        }

        return contextToPass;
    }

    async function onDelete() {
        try {
            await gridActions.onDelete(deleteModalData);
            setDeleteModalData(null);

            if (component.deleteEvent) {
                options.events.emit(`formio.${component.deleteEvent}`, component)
            }
        } catch {
            console.error("There was an error while trying to delete")
        }
    }
}
