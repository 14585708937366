import { isEmpty, uniq } from 'lodash';
import { dataTypes } from '../components/Form/CustomComponents/constants';
import { ApiService } from '../types';
import { GridComponentFetchData, CombinedFilterState } from '../types';
import { CompositeFilterDescriptor, FilterDescriptor, SortDescriptor, State, toODataString } from "@progress/kendo-data-query";
import { Column } from '../components/DynamicDataGridV2/hooks/useColumns';

export const pageTypes = new Set(["edit", "view", "add", "index"]);

export interface GridResponce {
    data: any[],
    count: number
}

export function usefetchGridData(columns: Column[], apiService: ApiService, throwAsyncError: (error: any) => void, onChange: (data: any) => void, component: GridComponentFetchData, filters: CombinedFilterState, sort?: SortDescriptor[], paging?: any) {

    async function fetchTableData(): Promise<GridResponce | undefined> {
        const attributeNames = columns
            .map(col => col.attribute)
            .concat(component.tableSelected.idColumn)
            .join(",");

        const shouldFetchData = attributeNames && apiService && component.tableSelected && !component.updateFromSubmission;
        if (!shouldFetchData) return;

        try {
            const columnData = await fetchTableColumns(apiService, component.tableSelected.name);
            const path = getFilteredPath(attributeNames, columnData);
            const responseData = await apiService.get(path);
            const formattedData = await formatTableData(responseData?.value, columnData);
            const count = responseData?.["@odata.count"] as number ?? formattedData.length;
            if (component.addDataToForm) {
                onChange({ data: formattedData });
            }
            return { data: formattedData, count };
        } catch (error) {
            throwAsyncError(error);
        }
    }

    function getFilteredPath(attributeNames: string, columnData: any[]) {
        const query = [`$attributes=${attributeNames}`];

        const path = `v2/assets/tablesv2/${component.tableSelected.name}`;

        var componentFilter: FilterDescriptor | undefined;
        if (component.filterProperty && component.filterProperty.navigationProperty && component.filterProperty.idColumn) {

            const filterValue = getLastUrlParam(window.location.pathname);
            const filterValueIsAssetId = !pageTypes.has(filterValue);

            if (filterValueIsAssetId) {
                componentFilter = {
                    field: `${component.filterProperty.navigationProperty}.${component.filterProperty.idColumn}`,
                    operator: 'eq',
                    value: `${filterValue}`
                };
            }
        }
        const lookupProperties = columnData.filter(x => x.type = "Lookup");

        const cleanedSort = sort?.map(s => cleanSort(s, lookupProperties));
        const filter = getFilter(componentFilter);
        const cleanedFilter = filter && cleanFilter(filter) as CompositeFilterDescriptor;
        const odataQuery = apiToODataString({ filter: cleanedFilter, sort: cleanedSort, skip: paging?.skip });
        query.push(odataQuery);
        query.push("$count=true");

        if (paging?.take)
            query.push(`$take=${paging?.take}`);

        return `${path}?${query.join('&')}`;
    };

    function apiToODataString(state: State) {
        const query = toODataString(state);

        if (query.indexOf("indexof(") === -1)
            return query;

        // replace indexof(attribute,'') eq -1
        //    with not contains(attribute,'')
        const newQuery = query
            .split("indexof(")
            .map((part, index) => index === 0 ? part : part.replace(") eq -1", ")"))
            .join("not contains(")

        return newQuery;
    }

    function cleanSort(s: SortDescriptor, properties: any[]) {
        const navigationProperty = s.field.split(".")[0];
        const property = properties.find(p => p.navigationProperty === navigationProperty)
        if (!property)
            return s;
        return { ...s, field: `${navigationProperty}.${property.idColumn}` };
    }

    type Filter = CompositeFilterDescriptor | FilterDescriptor;

    function cleanFilter(f: Filter): Filter {
        if ('filters' in f && f.filters)
            return { ...f, filters: f.filters.map((filter: any) => cleanFilter(filter)) };
        if ('field' in f && f.field?.toString()?.includes(".")) {
            const lookupFilter = { ...f, field: f.field.toString().split(".")[0] }
            if (f?.operator === "isnotnull")
                return { logic: "and", filters: [f, lookupFilter] };
            if (f?.operator === "isnull")
                return { logic: "or", filters: [f, lookupFilter] };
        }
        return f;
    }

    function getFilter(componentFilter: Filter | undefined): CompositeFilterDescriptor | undefined {
        const filterArray = componentFilter ? [componentFilter] : [];
        if (filters.external)
            filterArray.push(filters.external);
        if (filters.internal)
            filterArray.push(filters.internal);
        if (isEmpty(filterArray))
            return;

        return {
            logic: "and",
            filters: filterArray
        };
    }

    async function formatTableData(data: any[], tableColumns: any[]) {
        if (isEmpty(data)) return [];

        const dateTimeFields = tableColumns.filter(col => col.type === dataTypes.dateTime).map(col => col.value);
        const selectColumns = columns.filter(col => col.hasOwnProperty("optionSet"));
        const selectTables = selectColumns.map(col => col.optionSet.name);
        const uniqueSelectTables = uniq(selectTables);

        const selectOptionRequsts = uniqueSelectTables.map(async col => {
            const optionList = await apiService.get(`assets/tables/${col}`);
            return { table: col, options: optionList };
        })
        const selectOptionsResponse = await Promise.all(selectOptionRequsts);
        const selectOptions = selectOptionsResponse.reduce((acc, cur) => {
            return { [cur.table]: cur.options, ...acc };
        }, {} as any);

        return data.map(dataObj => {
            dateTimeFields.forEach(field => {
                dataObj[field] = dataObj[field] ? new Date(dataObj[field]) : dataObj[field];
            });

            selectColumns.forEach(field => {

                if (!dataObj[field.attribute]) {
                    return;
                }

                if (field.type === dataTypes.multiSelect) {
                    const selections = dataObj[field.attribute].split(",") as string[];
                    dataObj[field.attribute] = selections.map(selection => selectOptions[field.optionSet.name].find((option: any) => option.Value === parseInt(selection))?.Label).join("; ");
                }

                if (field.type === dataTypes.select) {
                    dataObj[field.attribute] = selectOptions[field.optionSet.name].find((option: any) => option.Value === dataObj[field.attribute])?.Label;
                }
            });
            return { ...dataObj };
        });
    }

    return { fetchTableData };
}

async function fetchTableColumns(apiService: ApiService, tableName: string) {
    return apiService.get(`form/schema/${tableName}/columns`);
}

export function getLastUrlParam(urlPath: string) {
    const urlParams = urlPath.split('/');
    if (urlParams[urlParams.length - 1] === '') {
        return urlParams[urlParams.length - 2];
    }
    return urlParams[urlParams.length - 1];
}