import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { updateAccountInfoSuccess, updateAccountInfoFailure } from '../store/actions/UserActions';
import { useIdentity, reshapeIdentity } from '../hooks/useIdentity'

import useThrowAsyncError from './asyncErrorHook';

const requests = {};

function useAssetManagementFetch() {
    const accountInfo = useSelector(state => state.user.accountInfo);
    const dispatch = useDispatch();
    const getIdentity = useIdentity();
    const { tenant } = useParams();

    const clearCache = useCallback(() => {
        requests[tenant] = {};
    }, [tenant])

    if (!requests[tenant])
        clearCache();

    const token = accountInfo.jwtIdToken;
    const throwAsyncError = useThrowAsyncError();

    const makeRequest = function (path, body, customConfig, token) {
        const headers = { 'Content-Type': 'application/json' };
        if (token) {
            headers.Authorization = `Bearer ${token}`;
        }

        const config = {
            ...customConfig,
            headers: {
                ...headers,
                ...customConfig.headers
            }
        }

        if (body) {
            config.body = JSON.stringify(body);
        }

        const tenantPath = process.env.NODE_ENV === "development" ? "" : `/${tenant}`
        const url = `${process.env.REACT_APP_AMM_URL}${tenantPath}/${path}`

        return fetch(url, config);

    }

    const authenticatedFetch = useCallback(async (path, { body, ...customConfig } = {}) => {

        let response = await makeRequest(path, body, customConfig, token)

        // We handle a 401 by requesting another token and retrying
        if (response.status === 401) {

            const identity = await getIdentity();

            if (identity) {

                // Updating the token will trigger the callback to re-run
                dispatch(updateAccountInfoSuccess(reshapeIdentity(identity)));
                return
            }
        }

        // If the response fails it will throw an error here.
        // We don't catch it as the calling component will catch it
        if (response.status == 401) {
            dispatch(updateAccountInfoFailure({ accountCode: response.status }));
            return
        }

        if (response.status == 403) {
            throw new Error("You do not have access");
        }

        const responseData = await response.json();

        if (responseData.error) {
            throw new Error(responseData.error.message);
        }
        return responseData.data ?? responseData;

    }, [tenant, throwAsyncError, token])

    const get = useCallback((path) => {
        if (requests[tenant][path])
            return requests[tenant][path];
        const config = { method: "GET" };
        var request = authenticatedFetch(path, config);
        request.catch(() => {
            if (requests[tenant][path] == request)
                requests[tenant][path] = null;
        });
        requests[tenant][path] = request;
        const cacheLife = process.env.REACT_APP_FETCH_CACHE_EXPIRY_MS ?? 60000;
        setTimeout(() => requests[tenant][path] = null, cacheLife);
        return request;
    }, [authenticatedFetch]);

    const post = useCallback((path, body) => {
        const config = { method: "POST", body };
        return authenticatedFetch(path, config);
    }, [authenticatedFetch])

    const put = useCallback((path, body) => {
        clearCache(); //Tech Debt 92079: Remove caching once V2 grid is ready.
        const config = { method: "PUT", body };
        return authenticatedFetch(path, config);
    }, [authenticatedFetch])

    // calling remove instead of delete because delete is a js reserved word
    const remove = useCallback((path) => {
        clearCache(); //Tech Debt 92079: Remove caching once V2 grid is ready.
        const config = { method: "DELETE" }
        return authenticatedFetch(path, config);
    }, [authenticatedFetch])

    return { get, post, put, remove, clearCache }
}

export default useAssetManagementFetch