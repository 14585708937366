import { useState } from "react";
import rangeRight from "lodash/rangeRight";
import difference from "lodash/difference";
import Renderers from "../Renderers";
import { InternalProperties } from "../Constants";
import { dataTypes } from "../../Form/CustomComponents/constants";

export const useBulkEdit = ({ primaryKey, initialColumns, gridData, setGridData, onChange }) => {
    const [selection, setSelection] = useState({ started: false });
    const renderers = new Renderers(enterEdit, exitEdit, InternalProperties.INLINE_EDIT, initialColumns, bulkEditStart, bulkEditHover);

    function bulkEditStart(dataItem, field) {
        const index = gridData.display.data.findIndex(item => item[primaryKey] == dataItem[primaryKey]);

        selection.started = true;
        selection.field = field;
        selection.value = dataItem[field];
        selection.startIndex = index;
        selection.endIndex = index;

        setSelection(selection);
    }

    function bulkEditHover(dataItem, field) {
        if (selection.started === false || selection.field != field) return;

        const displayData = gridData.display.data;
        const selectedIndex = displayData.findIndex(item => item[primaryKey] == dataItem[primaryKey]);

        const newIndexList = rangeRight(selectedIndex, selection.startIndex);
        const currentIndexList = rangeRight(selection.endIndex, selection.startIndex);

        //Add items to the selection
        const addIndexList = difference(newIndexList, currentIndexList)
        addIndexList.forEach(index => {
            const displayItem = gridData.display.data[index];
            displayItem[field] = selection.value;
        });

        //Remove items from the selection
        const removeIndexList = difference(currentIndexList, newIndexList)
        removeIndexList.forEach(index => {
            const displayItem = displayData[index];
            const valueItem = gridData.values.find(item => item[primaryKey] == displayItem[primaryKey]);
            displayItem[field] = valueItem[field];
        });

        selection.endIndex = selectedIndex;
        setGridData({ display: gridData.display });
    }

    function bulkEditFinish() {
        if (selection.started === false) return;
        selection.started = false;
        const field = selection.field;

        const editIndexList = rangeRight(selection.endIndex, selection.startIndex);
        editIndexList.forEach(index => {
            const displayItem = gridData.display.data[index];
            const primaryKeyValue = displayItem[primaryKey];
            const valueItem = gridData.values.find(item => item[primaryKey] == primaryKeyValue);
            const value = displayItem[field]
            valueItem[field] = value;
            putGridDataEditEntry(primaryKeyValue, field, value, gridData,);
        });
        onChange({ Data: gridData.edits });
        setGridData({ edits: gridData.edits });
    }

    function enterEdit(dataItem, field) {
        gridData.display.data
            .filter(item => item[primaryKey] === dataItem[primaryKey] || item[InternalProperties.INLINE_EDIT])
            .forEach(item => {
                if (item[primaryKey] === dataItem[primaryKey]) {
                    item[InternalProperties.INLINE_EDIT] = field;
                } else {
                    item[InternalProperties.INLINE_EDIT] = undefined
                }
            });

        gridData.values
            .filter(item => item[primaryKey] === dataItem[primaryKey] || item[InternalProperties.INLINE_EDIT])
            .forEach(item => {
                if (item[primaryKey] === dataItem[primaryKey]) {
                    item[InternalProperties.INLINE_EDIT] = field;
                } else {
                    item[InternalProperties.INLINE_EDIT] = undefined
                }
            });

        setGridData({
            display: { ...gridData.display },
            values: gridData.values,
        });
    }

    function exitEdit() {
        gridData.display.data
            .filter(item => item[InternalProperties.INLINE_EDIT])
            .forEach(item => { item[InternalProperties.INLINE_EDIT] = undefined; });

        gridData.values
            .filter(item => item[InternalProperties.INLINE_EDIT])
            .forEach(item => { item[InternalProperties.INLINE_EDIT] = undefined; });

        setGridData({
            display: { ...gridData.display },
            values: gridData.values,
        });
    }

    function putGridDataEditEntry(primaryKeyValue, field, value, gridData) {
        let editItem = gridData.edits.find(item => item.id === primaryKeyValue);

        if (!editItem) {
            editItem = {
                id: primaryKeyValue,
                body: {}
            }
            gridData.edits.push(editItem);
        }

        const editItemColumn = initialColumns.find(col => col.key === field);
        value = editItemColumn.property.type === dataTypes.integer ? Math.round(value) : value;

        editItem.body[field] = value;
    }

    return { bulkEditFinish, putGridDataEditEntry, renderers, selection }
}