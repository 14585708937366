import { DataResult } from "@progress/kendo-data-query";
import { isEmpty } from "lodash";
import { useMemo } from "react";
import { InternalProperties } from "../Constants";

export function useDisplayData(dataResult: DataResult, isAllSelected: boolean, primaryKey: string, selectedIds: string[]) {
    // This wont work

    const displayDataResult = useMemo(() => {
        if (isEmpty(selectedIds) && !isAllSelected)
            return dataResult;

        const data = dataResult.data.map((item) => {
            const itemId = item[primaryKey];
            return {
                ...item,
                [InternalProperties.SELECTED]: isAllSelected || selectedIds.includes(itemId)
            };
        });

        return { ...dataResult, data };
    }, [selectedIds, dataResult, isAllSelected, primaryKey]);

    return { displayDataResult }
}