import Alert from '@material-ui/lab/Alert';
import { Box } from "@material-ui/core";

export default function ErrorMessage({ children, message }) {

    return (
        <Box display="flex" mt={5} justifyContent="center">
            <Alert severity="error">
                {message}
                {children}
            </Alert>
        </Box>
    )
}