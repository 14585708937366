import { UPDATE_IMAGE } from "../ActionTypes";

// STATE
const defaultState = {
    image: '',
    displayButton: 'true',
    error: false
}

// REDUCER
export function sampleReducer(state = defaultState, action) {

    switch (action.type) {
        case UPDATE_IMAGE:
            {
                return {
                    ...state,
                    image: action.payload.image,
                    error: action.payload.error
                }
            }
        default:
            {
                return state;
            }
    }
}

