import useParentWindowIntegration, { eventType } from "../parentWindowIntegrationHook";
import { Identity } from ".";

export function useHostIdentity() {

    const { notifyDigitalTwin } = useParentWindowIntegration();

    return { getIdentity };

    function getIdentity() {

        return new Promise<Identity>((resolve, reject) => {

            window.addEventListener('message', onMessage);

            notifyDigitalTwin({}, eventType.GET_IDENTITY_TOKEN,);

            const timeoutId = setTimeout(() => {
                window.removeEventListener("message", onMessage);
                reject("No identity token received from the host");
            }, 1000);

            function onMessage(e: any) {
                const type = e?.data?.eventType;
                const payload = e?.data?.payload;
                const audience = window.location.origin;

                if (type === eventType.SET_IDENTITY_TOKEN && payload?.decoded.aud === audience) {

                    clearTimeout(timeoutId);
                    window.removeEventListener("message", onMessage);

                    if (payload?.token && payload?.decoded) {
                        resolve(payload);
                    }
                    else {
                        reject("Invalid token recevied from host");
                    }

                }
            }
        });
    }
}
