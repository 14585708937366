import { useHistory, useParams } from "react-router-dom";

import formTypes from "../enums/FormTypes";
import redirectLocations from '../enums/RedirectLocations';
import { urlBuilder } from "../util/helpers";
import useDigitalTwinIntegration, { eventType, navigationActions } from "./parentWindowIntegrationHook";
import useUriQuery from "./uriQueryHook"
import useBreadCrumbPath from "./useBreadCrumbPath";

export default function useNavigation() {
    const { breadcrumbPaths } = useBreadCrumbPath();

    const { notifyDigitalTwin } = useDigitalTwinIntegration();

    const { groupName, formId, assetId } = useParams();
    const history = useHistory();
    const uriQuery = useUriQuery();

    const { tenant } = useParams();

    function navigationTo({ pathBuilder, group, form, id, useContext }) {
        pathBuilder = pathBuilder || urlBuilder("");
        if (!pathBuilder.url) {
            group = group || groupName;
            form = form || formId;
            id = assetId || id;

            pathBuilder.url = `/${tenant}/form/${group}/${form}`;

            if (!!id && (form === formTypes.EDIT || form === formTypes.VIEW)) {
                notifyDigitalTwin({ tag: id }, eventType.SET_NAVIGATION);

                pathBuilder.url += `/${id}`;
            }
        }

        if (useContext && uriQuery.context) {
            Object.entries(uriQuery.context)
                .forEach(([key, value]) => pathBuilder.addQuery(`context[${key}]`, value));
        }
        const builtPath = pathBuilder.build();
        return { path: builtPath, go: () => go(builtPath) };
    };

    function go(path) {
        var editViewUrlRegex = new RegExp(/^(\/form\/)[\w]+\/(edit|view)\/([\w-]+)/)
        var match = window.location.pathname.match(editViewUrlRegex);
        if (match) {
            var id = match[3];
            notifyDigitalTwin({ tag: id }, eventType.SET_NAVIGATION);
            console.log("notify", id)
        }

        // If the url hasn't changed then use history.go(0) to refresh the current page
        const currentUrl = `${history.location.pathname}${history.location.search}`;
        if (decodeURI(currentUrl) === decodeURI(path)) {
            history.go(0)
        }
        else {
            history.push(path);
        }
    }

    function goBack() {
        if (breadcrumbPaths.length > 1) {
            var path = breadcrumbPaths[breadcrumbPaths.length - 2].path;
            go(path);
            return;
        }

        // Notify DT to go back to previous page
        notifyDigitalTwin({ action: navigationActions.PREVIOUS_PAGE }, eventType.SET_NAVIGATION);
        history.goBack();
    };

    function redirect({ locationToRedirect, transferContext, customRedirectCallback }) {
        switch (locationToRedirect) {
            case redirectLocations.PREVIOUS:
                return goBack();
            case redirectLocations.CUSTOM:
                return customRedirectCallback();
            default:
                return navigationTo({
                    form: locationToRedirect,
                    useContext: transferContext
                }).go();
        }
    }

    return { navigationTo, goBack, redirect };
}