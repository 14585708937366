import { ApiService, GridActions } from '../../../../../types';
import { urlBuilder } from '../../../../../util/helpers';
import useDigitalTwinIntegration, { eventType } from './../../../../../hooks/parentWindowIntegrationHook';

type GridActionsOptions = {
    isHardDelete?: boolean
}

export function useGridActions(fetch: ApiService, options: GridActionsOptions = {}): GridActions {
    const { notifyDigitalTwin } = useDigitalTwinIntegration();

    function onGridCreate(action: any) {
        notifyDigitalTwin({ action }, eventType.SET_NAVIGATION);
    }

    async function onGridDelete({ tableName, entityId, data }: { tableName: string, entityId: string, data: any }) {
        if (options.isHardDelete)
            return fetch.remove(`assets/tables/${tableName}/${entityId}`);
        return fetch.put(`assets/tables/${tableName}/${entityId}`, data);
    }

    function onGridEdit(data: string, action: any) {
        notifyDigitalTwin({ tag: data, action }, eventType.SET_NAVIGATION);
    }
    function onGridView(data: string, action: any) {
        notifyDigitalTwin({ tag: data, action }, eventType.SET_NAVIGATION);
    }

    async function onGridDownload(downloadOptions: any) {
        const pathBuilder = urlBuilder("storage/file/download");

        pathBuilder.query = Object.entries(downloadOptions)
            .filter(([_, value]) => value != null)
            .map(([key, value]) => `${key}=${value}`);

        const downloadPath = await fetch.get(pathBuilder.build());
        window.open(downloadPath);
    }

    function onSelection(selection: any[]) {
        const data = selection.length === 1 ? { tag: selection[0] } : { tags: selection };

        notifyDigitalTwin(data, eventType.SET_SELECTION);
    }

    return {
        onCreate: onGridCreate,
        onDelete: onGridDelete,
        onDownload: onGridDownload,
        onEdit: onGridEdit,
        onSelection: onSelection,
        onView: onGridView
    }
}