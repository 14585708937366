import { Typography } from '@material-ui/core';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { useEffect, useState } from 'react';
import { getNestedProperty } from '../../../../util/helpers';

import useThrowAsyncError from '../../../../hooks/asyncErrorHook';

export function Renderer(props: any) {
    const { component, options } = props;

    const throwAsyncError = useThrowAsyncError();

    const filterProperty = component?.filterOn;
    const valueProperty = component?.filterBy;
    const dataKeys = getDataKeys(component);
    const submissionData = options.root.submission.data;
    const isPreview = options.preview || options.attachMode === "builder";

    const [loading, setLoading] = useState<boolean>(true);
    const [value, setValue] = useState<any>();
    const [data, setData] = useState<any[]>();

    useEffect(() => {
        if (!component.isCascading || !component.cascadeFromApiKey)
            return;

        const listener = (e: Event) => queryData();
        window.addEventListener(`dropdown-${component.cascadeFromApiKey}`, listener);

        return () => window.removeEventListener(`dropdown-${component.cascadeFromApiKey}`, listener);
    }, [component.cascadeFromApiKey, component.isCascading, options.events]);

    useEffect(() => {
        //Wait until parent dropdown is set
        if (!component.isCascading) queryData()
    }, []);

    //Editing not supported for multi-level lookups
    const disabled = options.readOnly || component.levels?.length > 1 || loading;
    const textValue = getFormValueFromLevels(component.levels)?.[dataKeys.label];

    return component.disabled
        ? <Typography> {textValue} </Typography>
        : <DropDownList
            value={value}
            data={data}
            dataItemKey={dataKeys.idColumn}
            onChange={e => onSelection(e?.value)}
            textField={dataKeys.label}
            disabled={disabled}
        />;

    function onSelection(newValue: any) {
        setValue(newValue ?? null);

        if (component.levels.length == 1) {
            //Only allow editing for single level lookups
            submissionData[dataKeys.navigationProperty] = {
                ...submissionData[dataKeys.navigationProperty] ?? {},
                table: dataKeys.table, //Table to lookup from
                id: newValue?.[dataKeys.idColumn] //Row in the table to choose
            };
        }
        //Add data to submission to allow for custom validation
        submissionData[component.key] = newValue?.[dataKeys.label];
        window.dispatchEvent(new CustomEvent(`dropdown-${component.key}`));
    };

    function getDataKeys(comp: any) {
        return {
            ...comp.levels[comp.levels.length - 1].lookup,
            label: comp.displayAttribute.value,
        };
    }

    function getFormValueFromLevels(levels: any[]) {
        //Recursively drill down nested data for each level of the lookup
        return levels.reduce(
            (formValue: any, level: any) => formValue?.[level.lookup.navigationProperty],
            submissionData
        );
    }

    function queryData() {
        if (isPreview)
            return;

        const url = new URL(document.baseURI);
        url.searchParams.set("attributes", dataKeys.label);

        if (!loading) setLoading(true);

        if (filterProperty && valueProperty) {
            const filterKey = `${filterProperty.navigationProperty}.${filterProperty.idColumn}`;
            const filterValue = getNestedProperty(submissionData, `${valueProperty.navigationProperty}.id`);
            if (filterValue?.trim())
                url.searchParams.set(`filters[${filterKey}]`, filterValue);
        }
        options.fetch?.get(`assets/tables/${dataKeys.table}${url.search}`)
            .then((res: any[]) => {
                res.forEach(item => item[dataKeys.label] = String(item[dataKeys.label]));
                setData(res);
                if (loading) setLoading(false);
                const formValue = getFormValueFromLevels(component.levels);
                const defaultValue = res.find(item => item[dataKeys.idColumn] === formValue?.[dataKeys.idColumn]);
                onSelection(defaultValue);
            })
            .catch(throwAsyncError);
    }
}
